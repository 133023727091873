/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a neccessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import debounce from '../../utils/debounce.js'
import * as styles from './styles.css'
import { selectApp } from './selectors.js'
import { sizeShape } from './shape.js'
import { getDimensions } from './util.js'
import {
  setDimensions,
  setFeature,
  setResizing,
  startCaching,
} from './actions.js'

export class App extends React.Component { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    children: PropTypes.node,
    resizing: PropTypes.bool,
    liveResize: PropTypes.bool,
    setResizing: PropTypes.func.isRequired,
    size: sizeShape.isRequired,
    setDimensions: PropTypes.func.isRequired,
    // setFeature: PropTypes.func.isRequired,
    startCaching: PropTypes.func.isRequired,
  }

  constructor(props, context) {
    super(props, context)
    this.updateDimensions = debounce(this.updateDimensions, 500)
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
    this.updateDimensions()
    this.cacheTimeout = setTimeout(() => this.props.startCaching(), 1000)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
    clearTimeout(this.cacheTimeout)
  }

  onResize = () => {
    if (!this.props.resizing) {
      this.props.setResizing(true)
    }
    this.updateDimensions()
  }

  updateDimensions = () => {
    const newDim = getDimensions()
    if (
      newDim.width !== this.props.size.width
      || newDim.height !== this.props.size.height
    ) {
      this.props.setDimensions(newDim)
    }
  }

  render() {
    const appStyles = {}
    if (this.props.size.phone || this.props.size.tablet) {
      appStyles.fontSize = 10
    }

    // on tablet and desktop do not render on resize
    // on phone we need to render since the input in view
    // could loose focus and the keyboard can change the
    // the size -> endless loop
    if (!this.props.size.phone && !this.props.liveResize && this.props.resizing) {
      return <div />
    }

    return (
      <div className={styles.app} style={appStyles}>
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = selectApp()

function mapDispatchToProps(dispatch) {
  return {
    setDimensions: (dim) => dispatch(setDimensions(dim)),
    setResizing: (val) => dispatch(setResizing(val)),
    setFeature: (key, value) => dispatch(setFeature(key, value)),
    startCaching: () => dispatch(startCaching()),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
