/**
*
* InfoIcon
*
*/

import React from 'react'
import { PropTypes } from 'prop-types'
import clientConnect from '../../../containers/ClientContainer/connect.js'
import { createThemeStyles } from '../../../theme.js'
import withIconStyle from '../Icon.js'

class Star extends React.Component { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    size: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object,
    theme: PropTypes.object, // eslint-disable-line
  }

  static defaultProps = {
    size: 32,
  }

  getThemeStyles = createThemeStyles('StarIcon', (opts) => ({
    light: {
      fill: opts.starFillLight,
    },
    normal: {
      fill: opts.starFill,
    },
    dark: {
      fill: opts.starFillDark,
    },
    outline: {
      fill: opts.starOutline,
    },
  }))

  render() {
    const { size, style, className } = this.props
    const themeStyles = this.getThemeStyles()

    return (
      <div
        className={className}
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 128 128"
          style={{
            width: size,
            height: size,
            ...style,
          }}
        >
          <g id="star">
            <path
              id="outline"
              style={themeStyles.outline}
              d="M120.478,42.912L88.55,38.268c-1.039-0.147-1.937-0.801-2.4-1.742L71.871,7.596C70.382,4.578,67.365,2.702,64,2.702c-3.366,0-6.382,1.876-7.872,4.894L41.853,36.526c-0.466,0.941-1.363,1.595-2.406,1.746L7.523,42.912c-3.33,0.483-6.046,2.77-7.087,5.972c-1.04,3.201-0.19,6.651,2.221,8.999l23.102,22.521c0.753,0.73,1.097,1.789,0.917,2.825l-5.453,31.795c-0.439,2.56,0.26,5.154,1.919,7.119c1.69,2.005,4.144,3.155,6.733,3.155c1.421,0,2.796-0.344,4.085-1.023l28.558-15.012c0.455-0.24,0.968-0.367,1.483-0.367c0.516,0,1.028,0.127,1.486,0.367l28.554,15.012c1.289,0.679,2.662,1.023,4.084,1.023c2.59,0,5.046-1.151,6.735-3.155c1.658-1.965,2.356-4.562,1.918-7.119l-5.454-31.795c-0.178-1.036,0.166-2.095,0.92-2.828l23.1-22.518c2.411-2.351,3.261-5.8,2.22-9.001C126.522,45.682,123.807,43.395,120.478,42.912z M123.393,55.883l-23.101,22.518c-1.41,1.375-2.054,3.356-1.721,5.298l5.453,31.795c0.839,4.89-4.294,8.619-8.685,6.311l-28.554-15.012c-1.744-0.918-3.827-0.918-5.571,0L32.66,121.804c-4.391,2.308-9.523-1.421-8.684-6.311l5.454-31.795c0.332-1.942-0.311-3.923-1.722-5.298L4.607,55.883c-3.553-3.462-1.592-9.496,3.317-10.21l31.924-4.639c1.949-0.283,3.635-1.508,4.506-3.274L58.633,8.832c2.196-4.449,8.539-4.449,10.736,0L83.645,37.76c0.872,1.766,2.557,2.991,4.507,3.274l31.924,4.639C124.985,46.386,126.946,52.421,123.393,55.883z"
            />
            <path
              id="base"
              style={themeStyles.normal}
              d="M120.076,45.673l-31.924-4.639c-1.949-0.283-3.635-1.508-4.507-3.274L69.368,8.832c-2.197-4.449-8.54-4.449-10.736,0L44.355,37.76c-0.871,1.766-2.557,2.991-4.506,3.274L7.925,45.673c-4.91,0.713-6.87,6.748-3.317,10.21l23.101,22.518c1.411,1.375,2.054,3.356,1.722,5.298l-5.454,31.795c-0.839,4.89,4.294,8.619,8.684,6.311l28.554-15.012c1.744-0.918,3.827-0.918,5.571,0l28.554,15.012c4.391,2.308,9.524-1.421,8.685-6.311l-5.453-31.795c-0.333-1.942,0.311-3.923,1.721-5.298l23.101-22.518C126.946,52.421,124.985,46.386,120.076,45.673z"
            />
            <path
              style={themeStyles.dark}
              d="M123.37,55.912c2.348-2.289,2.252-5.674,0.524-7.93c-2.929,4.157-6.046,8.275-9.791,11.378c-5.875,4.867-13.183,8.426-18.37,14.093c-6.482,7.082,1.667,25.875-5.749,29.832c-6.355,3.391-15.708-6.226-20.903-8.845c-7.47-3.769-12.847,0.166-19.587,4.184c-4.563,2.722-7.294,5.625-10.979,0.513c-3.645-5.058-2.204-10.21-2.234-15.868c-0.058-10.921-6.891-12.942-15.266-17.698c-6.238-3.542-12.074-10.435-18.17-13.441c0.107,1.351,0.624,2.695,1.74,3.783L27.685,78.43c1.411,1.375,2.054,3.356,1.722,5.298l-0.633,3.69c-0.572,5.563-1.815,10.95-1.799,16.609c0.015,6.008-2.525,16.249,6.706,13.324c8.848-2.804,16.739-8.178,25.386-11.464c5.498-2.088,9.18-1.734,14.307,1.26c3.507,2.047,23.825,13.718,25.736,12.257c1.951-1.492,2.066-6.321,2.09-8.485c0.087-7.742-3.874-15.979-2.067-23.681l4.846,28.257c0.839,4.89-4.294,8.619-8.685,6.31L66.74,106.793c-1.744-0.918-3.827-0.918-5.571,0l-28.554,15.011c-2.74,1.441-5.769,0.529-7.455-1.547c1.682,2.097,4.724,3.023,7.477,1.576l28.554-15.011c1.743-0.917,3.827-0.917,5.57,0l28.554,15.011c4.391,2.308,9.524-1.421,8.685-6.31l-5.453-31.795c-0.333-1.942,0.31-3.923,1.721-5.298L123.37,55.912z"
            />
            <g id="highlight">
              <path
                style={themeStyles.light}
                d="M88.122,41.034c-1.949-0.283-3.636-1.508-4.507-3.274L69.338,8.832c-2.196-4.449-8.539-4.449-10.736,0L44.325,37.76c-0.872,1.766-2.557,2.991-4.507,3.274L7.894,45.673c-1.829,0.266-3.246,1.273-4.124,2.608c4.139,2.498,11.345,2.372,15.744,2.382c8.587,0.017,17.015-0.961,24.969-4.359c3.725-1.591,5.779-3.801,8.02-7.095c3.084-4.531,4.23-9.998,7.277-14.468c3.416-5.009,6.738-2.27,8.383,2.409c2.285,6.496,2.53,12.409,7.946,17.411c7.286,6.728,18.169,4.26,27.108,4.24c6.172-0.013,13.647-2.328,20.059-1.553c-0.842-0.798-1.921-1.384-3.232-1.575L88.122,41.034z"
              />
              <path style={themeStyles.light} d="M109.99,60.679c-2.737,0-2.742,4.254,0,4.254C112.727,64.934,112.732,60.679,109.99,60.679z" />
              <path
                style={themeStyles.light}
                d="M121.163,50.73c-2.037-0.485-3.822,1.764-5.035,3.152c-0.69,0.79-1.333,1.591-1.807,2.529c-0.558,1.102-0.412,1.5-0.336,2.659c0.009,0.148,0.127,0.308,0.294,0.294c0.898-0.074,1.395,0.057,2.227-0.429c0.969-0.567,1.797-1.291,2.602-2.073c1.355-1.317,3.544-2.994,3.125-5.063C122.132,51.301,121.649,50.846,121.163,50.73z"
              />
              <path style={themeStyles.light} d="M19.941,63.561c-2.742,0-2.737,4.254,0,4.254C22.683,67.815,22.678,63.561,19.941,63.561z" />
              <path
                style={themeStyles.light}
                d="M13.803,56.763c-1.213-1.387-2.998-3.636-5.035-3.152c-0.487,0.115-0.968,0.571-1.069,1.07c-0.419,2.068,1.77,3.746,3.125,5.062c0.805,0.781,1.633,1.506,2.602,2.073c0.831,0.487,1.329,0.355,2.226,0.43c0.168,0.013,0.286-0.146,0.294-0.295c0.076-1.159,0.222-1.556-0.336-2.659C15.136,58.354,14.493,57.554,13.803,56.763z"
              />
              <path style={themeStyles.light} d="M30.47,113.057c-0.855,2.605,3.189,3.927,4.042,1.326C35.367,111.778,31.323,110.456,30.47,113.057z" />
              <path
                style={themeStyles.light}
                d="M46.766,105.518c-1.835-1.043-4.11,0.515-5.785,1.391c-0.993,0.522-1.94,1.082-2.78,1.827c-0.721,0.638-0.752,1.153-1.102,1.982c-0.065,0.154,0.05,0.316,0.188,0.371c1.078,0.433,1.41,0.695,2.631,0.509c1.038-0.158,1.999-0.52,2.966-0.929c1.697-0.72,4.39-1.715,4.565-3.802C47.49,106.369,47.208,105.769,46.766,105.518z"
              />
              <path style={themeStyles.light} d="M94.688,115.023c0.854,2.601,4.896,1.279,4.042-1.326C97.876,111.096,93.833,112.419,94.688,115.023z" />
              <path
                style={themeStyles.light}
                d="M91,109.376c-0.841-0.743-1.788-1.304-2.782-1.826c-1.673-0.877-3.949-2.435-5.783-1.391c-0.442,0.251-0.725,0.851-0.683,1.349c0.175,2.087,2.868,3.082,4.565,3.801c0.965,0.41,1.927,0.771,2.966,0.93c1.221,0.186,1.553-0.076,2.631-0.509c0.138-0.055,0.252-0.217,0.188-0.371C91.751,110.528,91.721,110.015,91,109.376z"
              />
            </g>
            <g id="highlight-white">
              <path fill="#FFFFFF" d="M72.011,32.216c-1.647,3.974,4.527,6.539,6.176,2.558C79.833,30.801,73.659,28.236,72.011,32.216z" />
              <path
                fill="#FFFFFF"
                d="M71.95,16.684c-1.096-2.76-2.215-6.947-5.469-7.583c-0.785-0.154-1.736,0.272-2.197,0.91c-1.927,2.666,0.263,6.609,1.549,9.206c0.733,1.478,1.508,2.892,2.584,4.144c1.265,1.473,1.929,1.5,3.657,2.087c0.222,0.075,0.525,0.001,0.605-0.25c0.433-1.349,0.923-1.991,0.717-3.491C73.155,19.96,72.601,18.323,71.95,16.684z"
              />
            </g>
          </g>
        </svg>
      </div>
    )
  }
}

export default withIconStyle(clientConnect(Star))
