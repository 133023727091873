/**
*
* InventoryItemCount
*
*/

import React from 'react'
import { PropTypes } from 'prop-types'
import clientConnect from '../../containers/ClientContainer/connect.js'
import { createThemeStyles } from '../../theme.js'
import styled from 'styled-components'
import InventoryItem from '../InventoryItem/index.js'
import { FormattedMessage } from 'react-intl'

const Column = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Row = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
`

const Count = styled.div`
  align-self: center;
  flex: 0 0 auto;
  text-align: center;
`

class InventoryItemCount extends React.Component { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    theme: PropTypes.object, // eslint-disable-line
    style: PropTypes.object,
    item: PropTypes.object,
    count: PropTypes.number,
    size: PropTypes.number,
    error: PropTypes.bool,
    left: PropTypes.bool,
  }

  static defaultProps = {
    size: 48,
  }

  getThemeStyles = createThemeStyles('InventoryItemCount', (opts) => ({ // eslint-disable-line
    error: {
      color: opts.error,
    },
  }))

  render() {
    const {
      style, item, count, size, error, left,
    } = this.props
    const themeStyles = this.getThemeStyles()
    const countSize = Math.round(size / 2)
    let countFontSize = Math.round(countSize / 2)
    if (countFontSize < 14) {
      countFontSize = 14
    }

    const Container = left ? Row : Column
    return (
      <Container
        style={{
          ...style,
          ...(error ? themeStyles.error : {}),
          height: left ? size : size + countSize,
          width: left ? size + countSize : size,
        }}
      >
        {!left && <InventoryItem item={item} size={size} />}
        <Count
          style={{
            width: size,
            fontSize: `${countFontSize}px`,
          }}
        >
          <FormattedMessage
            id="inventory.times"
            defaultMessage="{count} ×"
            values={{
              count,
            }}
          />
        </Count>
        {left && <InventoryItem item={item} size={size} />}
      </Container>
    )
  }
}

export default clientConnect(InventoryItemCount)
