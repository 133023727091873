/*
 *
 * Quest constants
 *
 */

export const QUESTS_FETCH_REQUESTED = 'app/Quest/QUESTS_FETCH_REQUESTED'
export const QUESTS_FETCH_SUCCEEDED = 'app/Quest/QUESTS_FETCH_SUCCEEDED'
export const QUESTS_FETCH_FAILED = 'app/Quest/QUESTS_FETCH_FAILED'
export const QUESTS_SET_ANIMATIONS = 'app/Quest/QUESTS_SET_ANIMATIONS'
