/*
 *
 * Quest actions
 *
 */

import {
  QUESTS_FETCH_REQUESTED,
  QUESTS_FETCH_SUCCEEDED,
  QUESTS_FETCH_FAILED,
} from './constants.js'

export function fetchQuests() {
  return {
    type: QUESTS_FETCH_REQUESTED,
  }
}

export function fetchQuestsSucceeded(quests) {
  return {
    type: QUESTS_FETCH_SUCCEEDED,
    quests,
  }
}

export function fetchQuestsFailed(message) {
  return {
    type: QUESTS_FETCH_FAILED,
    message,
  }
}
