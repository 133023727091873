/*
 *
 * AdminLoginPage constants
 *
 */

export const LOGIN_REQUESTED = 'app/AdminLoginPage/LOGIN_REQUESTED'
export const LOGIN_SUCCEEDED = 'app/AdminLoginPage/LOGIN_SUCCEEDED'
export const LOGIN_FAILED = 'app/AdminLoginPage/LOGIN_FAILED'
export const LOGIN_CLIENTS = 'app/AdminLoginPage/LOGIN_CLIENTS'
export const LOGOUT = 'app/AdminLoginPage/LOGOUT'
export const SWITCH_CLIENT = 'app/AdminLoginPage/SWITCH_CLIENT'
