import localforage from 'localforage'

const checkInterval = 1000

export const ensureSingleInstance = (closeCallback) => {
  const startTime = new Date().getTime()
  localforage.setItem('startTime', startTime)
    .then(() => {
      // console.log(`set start time to ${startTime}`)
      const check = () => {
        localforage.getItem('startTime').then((startTimeFromStore) => {
          if (startTimeFromStore !== startTime) {
            // console.log(`found new instance with start time ${startTimeFromStore}`)
            closeCallback()
          } else {
            setTimeout(check, checkInterval)
          }
        }).catch((err) => {}) // eslint-disable-line
      }
      check()
    })
}
