import { delay } from 'redux-saga'
import {
  put, select, call, takeLatest, takeEvery,
} from 'redux-saga/effects'
import { push } from 'react-router-redux'
import { isHighResolution } from '../../utils/display.js'
import { getApi } from '../../api.js'
const Api = getApi()

import {
  RESET,
  MAINTENANCE,
  ADD_HTML_STYLE,
  ROUTE,
  START_CACHING,
  READ_CONTRACT,

  PROFILE_FETCH_REQUESTED,
  PROFILE_SAVE_REQUESTED,
  PROFILE_DELETE_REQUESTED,
  PROFILE_RESET_PASSWORD_REQUESTED,
  PROFILE_CHANGE_SERVER_LANGUAGE,

  INVENTORY_FETCH_REQUESTED,
  INVENTORY_OPEN,
} from './constants.js'

import {
  setRouteTransition,
  readContractSuccess,

  fetchProfileSucceeded,
  fetchProfileFailed,
  deleteProfileSucceeded,
  deleteProfileFailed,
  saved,

  fetchInventorySucceeded,
  fetchInventoryFailed,
} from './actions.js'

import { createToastyAction } from '../Toasty/actions.js'
import {
  loginUpdateSlogan,
  loginUpdateNickname,
  loginUpdateSounds,
} from '../LoginPage/actions.js'

function* logoutWorker() {
  const state = yield select()
  if (
    state.has('login')
    && state.get('login').has('options')
    && state.get('login').get('options').has('backLink')
    && state.get('login').get('options').get('backLink')
  ) {
    console.log('got backLink') // eslint-disable-line
    window.location.href = state.get('login').get('options').get('backLink')
  } else if (
    state.has('login')
    && state.get('login').has('options')
    && state.get('login').get('options').has('closeOnLogout')
    && state.get('login').get('options').get('closeOnLogout')
  ) {
    console.log('got closeOnLogout') // eslint-disable-line
    if (window.parent && window.parent.postMessage) {
      try {
        window.parent.postMessage('logout', '*')
      } catch (e) {} // eslint-disable-line
    }
    try {
      window.close()
    } catch (e) {} // eslint-disable-line
  } else if (
    state.has('app')
    && state.get('app').has('route')
    && state.get('app').get('route').has('redirectToLoginOnError')
    && state.get('app').get('route').get('redirectToLoginOnError')
    && state.get('app').get('route').has('client')
  ) {
    console.log('got redirectToLoginOnError') // eslint-disable-line
    const clientId = state.get('app').get('route').get('client')
    yield put(push(`/${clientId}/login`))
  } else {
    console.log('fallback logout') // eslint-disable-line
    window.location.reload()
  }
}

function* addHtmlStyleWorker(action) {
  const htmlEl = document.getElementsByTagName('html')[0]
  Object.keys(action.style).forEach((name) => {
    htmlEl.style[name] = action.style[name]
  })
}

function* resetTransitionWorker() {
  yield delay(500)
  const state = yield select()
  if (
    state.get('app').get('features').get('slideTransitions')
    && state.get('app').get('transition') === 'backward'
  ) {
    yield put(setRouteTransition('forward'))
  }
}

// cache avatars
function* cacheWorker() {
  if (window.cordova || window.cdn === false) {
    return // do not cache on cordova or without cdn
  }
  try {
    const state = yield select()
    const clientId = state.get('app').get('route').get('client')
    const resolution = isHighResolution() ? 'high' : 'low'
    const images = yield call(Api.fetchCache, clientId, resolution)

    console.log(`Starting prefetching of ${images.length} avatar images...`) // eslint-disable-line
    const startTime = new Date().getTime()
    const preloads = images.map((url) => new Promise((resolve) => {
      const link = document.createElement('link')
      link.rel = 'prefetch'
      link.href = url
      link.onload = resolve
      document.head.append(link)
    }))

    yield Promise.all(preloads)

    const endTime = new Date().getTime()
    const prefetchTime = Math.round((endTime - startTime) / 100) / 10
    console.log(`Finished prefetching of ${images.length} avatar images in ${prefetchTime}s`) // eslint-disable-line
    window.tracker.trackEvent('Prefetch', 'Avatars', resolution, prefetchTime)
  } catch (e) {} // eslint-disable-line
}

// contract
function* contractWorker(action) {
  try {
    yield call(Api.readUserContract, action.contract.id, action.accepted)
  } catch (e) {} // eslint-disable-line
  if (!action.accepted && action.contract.decline !== 'ignore') {
    if (action.contract.decline === 'logout') {
      yield put({ type: RESET })
    } else {
      console.warn(`decline action ${action.contract.decline} not implemented`) // eslint-disable-line
    }
  }
  yield put(readContractSuccess(action.contract, action.accepted))
}

// profile
function* deleteProfileWorker() {
  try {
    yield call(Api.deleteMe)
    yield put(deleteProfileSucceeded())
    yield put({ type: RESET })
  } catch (e) {
    yield put(deleteProfileFailed(e.message))
  }
}

function* fetchProfileWorker() {
  try {
    const profile = yield call(Api.fetchProfile)
    const languages = yield call(Api.languages)
    yield put(fetchProfileSucceeded(profile, languages))
    const state = yield select()
    if (profile.language !== state.get('language')) {
      console.log(`Server profile language '${profile.language}' is not browser language '${state.get('language')}'`) // eslint-disable-line
    }
  } catch (e) {
    yield put(fetchProfileFailed(e.message))
  }
}

function* changeLanguageWorker(action) {
  const newLangauge = action.id
  const state = yield select()
  const profile = state.get('app').get('profile').toJS()
  profile.language = newLangauge
  const serverProfile = yield call(Api.saveProfile, profile)
  yield put(fetchProfileSucceeded(serverProfile))
}

function* saveProfileWorker() {
  // debounce by 800ms
  yield call(delay, 800)
  const state = yield select()
  const profile = state.get('app').get('profile').toJS()
  const newLanguage = profile.unsavedLanguage || state.get('language')
  profile.language = newLanguage
  try {
    const serverProfile = yield call(Api.saveProfile, profile)
    yield put(fetchProfileSucceeded(serverProfile))
    if (serverProfile.errors && Object.keys(serverProfile.errors).length) {
      const firstKey = Object.keys(serverProfile.errors)[0]
      const error = serverProfile.errors[firstKey]
      yield put(createToastyAction({
        type: 'info',
        uniq: 'options.error',
        style: 'error',
        messageId: error.id,
        messageDefault: error.defaultMessage,
        messageValues: error.values,
      }))
    } else {
      yield put(loginUpdateSlogan(serverProfile.slogan))
      yield put(loginUpdateNickname(serverProfile.nickname))
      yield put(loginUpdateSounds(serverProfile.sounds))
      yield put(createToastyAction({
        type: 'info',
        uniq: 'options.saved',
        style: 'success',
        messageId: 'options.saved',
        messageDefault: 'Options saved',
      }))
      yield put(saved())

      // route to option menu
      // const clientId = state.get('app').get('route').get('client')
      // yield put(push(`/${clientId}/options`))
    }
    if (state.get('language') !== newLanguage) {
      console.log(`language changed to ${newLanguage}`) // eslint-disable-line
      const newUrl = `/${newLanguage}${window.location.pathname}`
      window.location = newUrl
    }
  } catch (e) {
    // TODO: add error handling
  }
}

function* resetPasswordWorker() {
  try {
    yield call(Api.resetPassword)
  } catch (e) {
    // pass
  }
}

function* fetchInventoryWorker() {
  try {
    const inventory = yield call(Api.fetchUserInventory)
    yield put(fetchInventorySucceeded(inventory))
  } catch (e) {
    yield put(fetchInventoryFailed(e.message))
  }
}

function* maintenanceWorker() {
  yield put(push('/error/maintenance'))
}

export function* fetchProfileSaga() {
  yield* yield takeLatest(PROFILE_FETCH_REQUESTED, fetchProfileWorker)
}

export function* saveProfileSaga() {
  yield* yield takeLatest(PROFILE_SAVE_REQUESTED, saveProfileWorker)
}

export function* deleteProfileSaga() {
  yield* yield takeLatest(PROFILE_DELETE_REQUESTED, deleteProfileWorker)
}

export function* logoutSaga() {
  yield takeLatest(RESET, logoutWorker)
}

export function* addHtmlStyleSaga() {
  yield takeEvery(ADD_HTML_STYLE, addHtmlStyleWorker)
}

export function* resetTransitionSaga() {
  yield takeEvery(ROUTE, resetTransitionWorker)
}

export function* cacheSaga() {
  yield takeLatest(START_CACHING, cacheWorker)
}

export function* contractSaga() {
  yield takeEvery(READ_CONTRACT, contractWorker)
}

export function* resetPasswordSaga() {
  yield takeEvery(PROFILE_RESET_PASSWORD_REQUESTED, resetPasswordWorker)
}

export function* fetchInventorySaga() {
  yield takeLatest([INVENTORY_FETCH_REQUESTED, INVENTORY_OPEN], fetchInventoryWorker)
}

export function* maintenanceSaga() {
  yield takeLatest(MAINTENANCE, maintenanceWorker)
}

export function* changeServerLanguageSaga() {
  yield takeLatest(PROFILE_CHANGE_SERVER_LANGUAGE, changeLanguageWorker)
}

// All sagas to be loaded
export default [
  logoutSaga,
  maintenanceSaga,
  addHtmlStyleSaga,
  resetTransitionSaga,
  cacheSaga,
  contractSaga,

  fetchProfileSaga,
  saveProfileSaga,
  deleteProfileSaga,
  resetPasswordSaga,
  changeServerLanguageSaga,

  fetchInventorySaga,
]
