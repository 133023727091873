/*
 *
 * Foreplay reducer
 *
 */

import { fromJS } from 'immutable'
import createReducer from '../../createReducer.js'
import { RESET } from '../App/constants.js'
import {
  FOREPLAY_FETCH_REQUESTED,
  FOREPLAY_FETCH_SUCCEEDED,
  FOREPLAY_FETCH_FAILED,
} from './constants.js'

const initialState = fromJS({
  loaded: false,
  quiz: null,
  error: null,
})

export const reducer = createReducer(initialState, {

  [RESET]() {
    return initialState
  },

  [FOREPLAY_FETCH_REQUESTED](state, action) {
    if (!state.get('loaded') || action.quizId !== state.get('quiz').get('id')) {
      return initialState
    }
    return state
  },

  [FOREPLAY_FETCH_SUCCEEDED](state, action) {
    return state.merge({
      quiz: action.quiz,
      loaded: true,
    })
  },

  [FOREPLAY_FETCH_FAILED](state, action) {
    return state.merge({
      loaded: false,
      error: action.message,
    })
  },
})

export default reducer
