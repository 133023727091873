export const RESET = 'app/RESET'
export const MAINTENANCE = 'app/MAINTENANCE'
export const REGENERATE_TOKEN = 'app/REGENERATE_TOKEN'
export const REQUEST_COUNT_SET = 'app/REQUEST_COUNT_SET'
export const ADD_HTML_STYLE = 'app/ADD_HTML_STYLE'
export const SET_DIMENSIONS = 'app/SET_DIMENSIONS'
export const ROUTE = 'app/ROUTE'
export const SET_LOGIN_REDIRECT = 'app/SET_LOGIN_REDIRECT'
export const SET_ROUTE_TRANSITION = 'app/SET_ROUTE_TRANSITION'
export const SET_FEATURE = 'app/SET_FEATURE'
export const SET_ONLINE_USERS = 'app/SET_ONLINE_USERS'
export const SET_RESIZING = 'app/SET_RESIZING'
export const START_CACHING = 'app/START_CACHING'
export const SET_OS = 'app/SET_OS'
export const SET_CONTRACTS = 'app/SET_CONTRACTS'
export const READ_CONTRACT = 'app/READ_CONTRACT'
export const READ_CONTRACT_SUCCESS = 'app/READ_CONTRACT_SUCCESS'
export const SET_LIVE_RESIZE = 'app/SET_LIVE_RESIZE'

// profile actions
export const PROFILE_FETCH_REQUESTED = 'app/PROFILE_FETCH_REQUESTED'
export const PROFILE_FETCH_SUCCEEDED = 'app/PROFILE_FETCH_SUCCEEDED'
export const PROFILE_FETCH_FAILED = 'app/PROFILE_FETCH_FAILED'
export const PROFILE_TOGGLE_SEND_NOTIFICATIONS = 'app/PROFILE_TOGGLE_SEND_NOTIFICATIONS'
export const PROFILE_TOGGLE_SEND_NEWS = 'app/PROFILE_TOGGLE_SEND_NEWS'
export const PROFILE_TOGGLE_FIND_ME_BY_EMAIL = 'app/PROFILE_TOGGLE_FIND_ME_BY_EMAIL'
export const PROFILE_TOGGLE_SOUND = 'app/PROFILE_TOGGLE_SOUND'
export const PROFILE_SET_SLOGAN = 'app/PROFILE_SET_SLOGAN'
export const PROFILE_SET_NICKNAME = 'app/PROFILE_SET_NICKNAME'
export const PROFILE_SAVE_REQUESTED = 'app/PROFILE_SAVE_REQUESTED'
export const PROFILE_SAVED = 'app/PROFILE_SAVED'
export const PROFILE_TOGGLE_SAVEDELETE = 'app/PROFILE_TOGGLE_SAVEDELETE'
export const PROFILE_DELETE_REQUESTED = 'app/PROFILE_DELETE_REQUESTED'
export const PROFILE_DELETE_SUCCEEDED = 'app/PROFILE_DELETE_SUCCEEDED'
export const PROFILE_DELETE_FAILED = 'app/PROFILE_DELETE_FAILED'
export const PROFILE_RESET_PASSWORD_REQUESTED = 'app/PROFILE_RESET_PASSWORD_REQUESTED'
export const PROFILE_CHANGE_LANGUAGE = 'app/PROFILE_CHANGE_LANGUAGE'
export const PROFILE_CHANGE_SERVER_LANGUAGE = 'app/PROFILE_CHANGE_SERVER_LANGUAGE'
export const PROFILE_SET_FIRSTNAME = 'app/PROFILE_SET_FIRSTNAME'
export const PROFILE_SET_LASTNAME = 'app/PROFILE_SET_LASTNAME'
export const PROFILE_SET_GENDER = 'app/PROFILE_SET_GENDER'
export const PROFILE_SET_COUNTRY = 'app/PROFILE_SET_COUNTRY'

export const INVENTORY_FETCH_REQUESTED = 'app/INVENTORY_FETCH_REQUESTED'
export const INVENTORY_FETCH_SUCCEEDED = 'app/INVENTORY_FETCH_SUCCEEDED'
export const INVENTORY_FETCH_FAILED = 'app/INVENTORY_FETCH_FAILED'

export const INVENTORY_OPEN = 'app/INVENTORY_OPEN'
export const INVENTORY_CLOSE = 'app/INVENTORY_CLOSE'
