import FileSaver from 'file-saver'
import features from './modernizr.js'

let regeneratePromise = null
let token
let requestId = 0
let panding = []
let language = 'en'
let onLoginCallback = () => true
let onTokenRegenerateCallback = () => true
let onPandingRequestCountChangeCallback = () => true
let onMaintenanceCallback = () => true

let base = `${window.location.protocol}//${window.location.host}/api`
let uploadBase = `${window.location.protocol}//${window.location.host}/uploads`

/**
 * used in player to set the upload url to a relative path
 */
export const useRelativeUploadUrl = () => {
  uploadBase = '.'
}

// TODO remove localStorage
try {
  if (features.localStorage && window.location.host.startsWith('localhost')) {
    if (localStorage.apiBase) {
      base = `${window.location.protocol}//${localStorage.apiBase}/api`
      uploadBase = `${window.location.protocol}//${localStorage.apiBase}/uploads`
    }
  }
} catch (e) {
  // eslint-disable-next-line no-console
  console.error(e)
}

// TODO: use this version if all image paths are fixed
// export const getUploadUrl = (rel) => `${uploadBase}${rel}`
export const getUploadUrl = (rel) => {
  if (!rel) {
    return 'https://static.bizquiz.cloud/logo-bizquiz-green.svg'
  }

  if (rel.substr(0, 8) === '/uploads') {
    console.warn('found old image path', rel) // eslint-disable-line
    return `${uploadBase}${rel.substr(8)}`
  }
  return `${uploadBase}${rel}`
}

export const getApiImage = (rel) => `${base}/image${rel}`

export const getApiDownload = (rel) => `${base}/download${rel}`

export const getCrestImage = (options) => {
  const opts = {
    mask: 1,
    pattern: 1,
    color1: '71BF43',
    color2: 'EC008B',
    color3: '2E3E4D',
    size: 64,
    ...options,
  }
  return `${base}/image/crest_${opts.mask}_${opts.pattern}_${opts.color1.toUpperCase()}_${opts.color2.toUpperCase()}_${opts.color3.toUpperCase()}_${opts.size}.png`
}

export const getItemCardImage = (options) => {
  const opts = {
    map: 'item',
    item: 1,
    color: '004185',
    ...options,
  }
  return `${base}/image/${opts.map}_${opts.item}_${opts.color}.png`
}

export const setToken = (t) => {
  token = t
}

export const setOnLogin = (f) => {
  onLoginCallback = f
}

export const setOnTokenRegenerate = (f) => {
  onTokenRegenerateCallback = f
}

export const setOnPandingRequestCountChange = (f) => {
  onPandingRequestCountChangeCallback = f
}

export const setOnMaintenanceCallback = (f) => {
  onMaintenanceCallback = f
}

export const setLanguage = (lang) => {
  language = lang
}

const parseJSON = (response) => response.json()

const logAndThrow = (err) => {
  console.error(err) // eslint-disable-line no-console
  throw err
}

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response
  }
  if (response.status === 401 || response.status === 403) {
    onLoginCallback()
  }
  if (response.status === 503) {
    onMaintenanceCallback()
  }
  return parseJSON(response).then((err) => {
    const error = new Error(err.message || response.statusText)
    error.response = response
    error.jsonError = true
    throw error
  }).catch((err) => {
    let finalError = err
    // not a json response
    if (!err.jsonError) {
      finalError = new Error(response.statusText)
      finalError.response = response
    }
    logAndThrow(finalError)
  })
}

// set the token and execute
const requestObject = (reqObject) => {
  // add the token to the options
  const optionsWithToken = {
    ...reqObject.options,
    headers: {
      ...reqObject.options.headers,
      Token: token,
    },
  }
  return fetch(reqObject.url, optionsWithToken)
    .then(postRequestHandler(reqObject.id)) // eslint-disable-line no-use-before-define
}

// generate an id and wait for the regeneratePromise
const executeRequestObject = (reqObject) => {
  const reqObjectWithId = {
    ...reqObject,
    id: requestId++,
  }
  panding.push(reqObjectWithId)
  onPandingRequestCountChangeCallback(panding.length)

  // console.log(`start ${reqObjectWithId.url}`)

  if (regeneratePromise) {
    // console.log(`start ${reqObjectWithId.url} waiting to regeneration`)
    return regeneratePromise.then(() => requestObject(reqObjectWithId))
  }

  return requestObject(reqObjectWithId)
}

const postRequestHandler = (pandingRequestId) => (response) => {
  const pandingRequest = panding.filter((pr) => pr.id === pandingRequestId)[0]
  panding = panding.filter((pr) => pr.id !== pandingRequestId)
  onPandingRequestCountChangeCallback(panding.length)

  // console.log(`post ${pandingRequest.url}`)

  // we need to regenerate
  if (response.status === 204) {
    // if we do not currently regenerte make the request
    if (!regeneratePromise) {
      // befor we make the request mark all pending request to rerun on error
      // console.log(`post ${pandingRequest.url} start regeneration`)
      panding = panding.map((p) => { // eslint-disable-line arrow-body-style
        return {
          ...p,
          rerun: true,
        }
      })
      regeneratePromise = fetch(`${base}/regenerate`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
        }),
      })
        .then(checkStatus)
        .then(parseJSON)
        .then((res) => {
        // set the token
          setToken(res.token)
          // inform the store
          onTokenRegenerateCallback(res.token)
          // delete the regenerating promise
          regeneratePromise = null
        // console.log(`post ${pandingRequest.url} end regeneration`)
        })
    }
  }

  // create a new Reqest object
  const newReqObject = {
    ...pandingRequest,
    rerun: false,
  }

  if (regeneratePromise) {
    // wait for the regeneratePromise and rerun with a new token
    // console.log(`post ${pandingRequest.url} waiting for regeneration`)
    return regeneratePromise.then(() => executeRequestObject(newReqObject))
  }

  if ((response.status === 401 || response.status === 403) && pandingRequest.rerun) {
    // we have been marked to rerun on error so reexecute the request
    // console.log(`post ${pandingRequest.url} rerun`)
    return executeRequestObject(newReqObject)
  }

  // else just return the response
  return response
}

// export only for plugins
export const req = (path, data, form, download) => {
  const options = {
    method: (data || form) ? 'POST' : 'GET',
    headers: {
      'Accept-Language': `${language}-${language.toUpperCase()}`,
      'Content-Type': 'application/json',
    },
  }
  if (data) {
    options.body = JSON.stringify(data)
  }
  if (form) {
    delete options.headers
    options.body = form
  }
  const url = base + (path || '')
  const reqObject = {
    url,
    options,
    rerun: false,
  }
  if (download) {
    return executeRequestObject(reqObject)
      .then(checkStatus)
      .then((res) => {
        let filename = download
        const dispo = res.headers.get('Content-Disposition')
        if (dispo && dispo.substr(0, 22) === 'attachment; filename="') {
          filename = dispo.substr(22, dispo.length - 23)
        }
        return res.blob().then((blob) => FileSaver.saveAs(blob, filename))
      })
  }
  return executeRequestObject(reqObject)
    .then(checkStatus)
    .then(parseJSON)
}

// controlling
export const fetchLogs = () => req('/controlling/logs')

// notifications
export const fetchNotifications = () => req('/notifications')

// client selection
export const fetchClients = () => req('/clients')

// translations

export const strings = (clientId) => req(`/strings/${clientId}`)

export const languages = () => req('/languages')

export const defaults = (credentials) => req('/defaults', credentials)

// login
export const login = (credentials) => req('/login', credentials)

export const logout = () => req('/logout', {})

export const validateClient = (id) => req('/client', { id })

export const fetchUserContracts = () => req('/user/contracts')

export const fetchUserQuests = () => req('/user/quests')

export const fetchUserInventory = () => req('/user/inventory')

export const fetchUserExtension = () => req('/user/extension')

export const saveUserExtension = (ext) => req('/user/extension', ext)

export const fetchContracts = (clientId) => req(`/contracts/${clientId}`)

export const fetchContract = (id) => req(`/contract/${id}`)

export const readUserContract = (id, accept) => req(`/user/contract/${id}/${accept ? 'accept' : 'decline'}`, {})

export const fetchClient = (id) => req(`/client/${id}`)

export const fetchCache = (id, res) => req(`/cache/${id}/${res}`)

export const fetchConfirmationInfo = (confirmationToken) => req('/confirmation', { token: confirmationToken })

export const saveConfirmation = (credentials) => req('/confirmation/store', credentials)

export const resetPassword = (email) => {
  if (email) {
    return req('/confirmation/request', email)
  }
  return req('/user/reset', {})
}

export const register = (account) => req('/register', account)

// profile
export const fetchProfile = () => req('/profile')

export const saveProfile = (profile) => req('/profile', profile)

// avatar selection
export const fetchAvatarlist = () => req('/avatars')

export const saveAvatarSelection = (avatarSelection) => req('/avatar', avatarSelection)

export const fetchCrestOptions = () => req('/image/crest/options')

// dashboard
export const fetchDashboard = () => req('/dashboard')

export const fetchPreviousDashboard = () => req('/dashboard/prev')

// quiz list
export const fetchQuizlist = () => req('/quizs')

export const fetchQuizDetail = (id) => req(`/quiz/${id}`)

// quiz instance
export const createTrainingQuiz = (quizId, priceId) => req(`/create/training/${quizId}${priceId ? `?price=${priceId}` : ''}`, {})

export const createChallengeQuiz = (quizId, priceId) => req(`/create/challenge/${quizId}${priceId ? `?price=${priceId}` : ''}`, {})

export const createUserChallengeQuiz = (quizId, userId, priceId) => req(`/create/userchallenge/${quizId}/${userId}${priceId ? `?price=${priceId}` : ''}`, {})

export const fetchQuizInstance = (quizInstanceId) => req(`/quizinstance/${quizInstanceId}`)

export const saveQuizInstanceEvaluations = (quizInstanceId, evaluations) => req(`/quizinstance/${quizInstanceId}`, evaluations)

export const fetchQuizInstanceResult = (quizInstanceId) => req(`/quizinstance/${quizInstanceId}/result`)

export const surrenderQuizInstance = (quizInstanceId) => req(`/quizinstance/${quizInstanceId}/surrender`, {})

export const fetchPlayerSearch = (quizId, query) => req('/users', { quizId, query })

export const fetchOpenChallenges = (quizId) => req('/openchallenges', { quizId })

export const fetchContent = (contentId) => req(`/content/${contentId}`)

export const fetchTasks = () => req('/tasks')

export const createTask = (task) => req('/tasks', task)

// users

export const fetchOnlineUsers = () => req('/users/online')

export const deleteMe = () => req('/delete', {})

// editor

export const sendTestMail = (email) => req('/editor/test/mail', { email })

export const sendInviteUser = (email) => req('/editor/invite/user', { email })

export const sendInviteAll = () => req('/editor/invite/all', {})

export const sendInvite = (userIds) => req('/editor/invite/ids', { userIds })

export const quizTest = (id) => req(`/editor/quiztest/${id}`)

// admin

export const importFile = (type, target, form) => req(`/admin/io/import/${type}${target ? `/${target}` : ''}`, undefined, form)

export const exportFile = (type, format, source, filename) => req(`/admin/io/export/${type}/${format}${source ? `/${source}` : ''}`, null, null, filename)

export const fetchAdminUsers = () => req('/admin/users')

export const fetchAdminUser = (id) => req(`/admin/user/${id}`)

export const saveAdminUser = (user) => req(`/admin/user/${user.id}`, user)

export const createAdminUser = (user) => req('/admin/user', user)

export const deleteAdminUser = (user) => req(`/admin/user/${user.id}/delete`, user)

export const exportAdminUser = (user) => req(`/admin/userexport/${user.id}`, null, null, `BizQuiz User ${user.id}.json`)

export const fetchAdminQuizlist = () => req('/admin/quizlist')

export const saveAdminQuizlistSorting = (sortings) => req('/admin/quizlist', sortings)

export const fetchAdminQuiz = (id) => req(`/admin/quiz/${id}`)

export const saveAdminQuiz = (quiz) => req(`/admin/quiz/${quiz.id}`, quiz)

export const createAdminQuiz = (quiz) => req('/admin/quiz', quiz)

export const deleteAdminQuiz = (quiz) => req(`/admin/quiz/${quiz.id}/delete`, quiz)

export const fetchAdminQuizTranslationCost = (id, lang) => req(`/admin/quiz/${id}/translationcost/${lang}`)

export const stackInfoAdminQuiz = (id) => req(`/admin/quiz/${id}/stack`)

export const fetchAdminTeamlist = () => req('/admin/teamlist')

export const fetchAdminTeam = (id) => req(`/admin/team/${id}`)

export const saveAdminTeam = (team) => req(`/admin/team/${team.id}`, team)

export const createAdminTeam = () => req('/admin/team', {})

export const deleteAdminTeam = (team) => req(`/admin/team/${team.id}/delete`, team)

export const fetchAdminAccessgrouplist = () => req('/admin/accessgroups')

export const createAdminAccessGroup = (accessGroup) => req('/admin/accessgroups', accessGroup)

export const fetchAdminAccessGroup = (id) => req(`/admin/accessgroups/${id}`)

export const saveAdminAccessGroup = (accessGroup) => req(`/admin/accessgroups/${accessGroup.id}`, accessGroup)

export const deleteAdminAccessGroup = (accessGroup) => req(`/admin/accessgroups/${accessGroup.id}/delete`, accessGroup)

export const fetchAdminReport = (options) => req('/admin/report', options)

export const fetchAdminItemReport = (options) => req('/admin/report/items', options)

export const fetchAdminSlide = (id) => req(`/admin/slide/${id}`)

export const saveAdminSlide = (slide) => req(`/admin/slide/${slide.id}`, slide)

export const fetchAdminSkills = () => req('/admin/skills')

export const createAdminSkill = (skill) => req('/admin/skills', skill)

export const fetchAdminSkill = (id) => req(`/admin/skills/${id}`)

export const saveAdminSkill = (skill) => req(`/admin/skills/${skill.id}`, skill)

export const deleteAdminSkill = (skill) => req(`/admin/skills/${skill.id}/delete`, skill)

export const fetchAdminChapters = () => req('/admin/chapters')

export const createAdminChapter = (chapter) => req('/admin/chapters', chapter)

export const fetchAdminChapter = (id) => req(`/admin/chapters/${id}`)

export const saveAdminChapter = (chapter) => req(`/admin/chapters/${chapter.id}`, chapter)

export const deleteAdminChapter = (chapter) => req(`/admin/chapters/${chapter.id}/delete`, chapter)

export const createAdminAutofix = (fixs) => req('/editor/import/autofix', fixs)

export const fetchAdminItems = () => req('/admin/items')

export const fetchAdminItemImages = () => req('/admin/itemimages')

export const createAdminItem = (item) => req('/admin/items', item)

export const fetchAdminItem = (id) => req(`/admin/items/${id}`)

export const saveAdminItem = (item) => req(`/admin/items/${item.id}`, item)

export const deleteAdminItem = (item) => req(`/admin/items/${item.id}/delete`, item)

export const fetchAdminQuests = () => req('/admin/quests')

export const fetchAdminQuestTypes = () => req('/admin/questtypes')

export const createAdminQuest = (quest) => req('/admin/quests', quest)

export const fetchAdminQuest = (id) => req(`/admin/quests/${id}`)

export const saveAdminQuest = (quest) => req(`/admin/quests/${quest.id}`, quest)

export const deleteAdminQuest = (quest) => req(`/admin/quests/${quest.id}/delete`, quest)

export const getMetabaseSSOUrl = () => `${base}/admin/metabase?apikey=${token}`

export const fetchAdminQuestions = (quizId) => req(`/admin/quiz/${quizId}/items`)

export const fetchAdminLeaderboard = (quizId) => req(`/admin/quiz/${quizId}/leaderboard`)

export const fetchAdminQuestion = (question) => req(`/admin/items/${question.type}/${question.id}`)

export const saveAdminQuestion = (question) => req(`/admin/items/${question.type}/${question.id}`, question)

export const createAdminQuestion = (quizId, question) => req(`/admin/quiz/${quizId}/items/${question.type}`, question)

export const deleteAdminQuestion = (question) => req(`/admin/items/${question.type}/${question.id}/delete`, question)

export const uploadQuizImage = (quizId, form) => req(`/admin/quiz/${quizId}/image`, undefined, form)

export const openMetabase = () => window.open(getMetabaseSSOUrl(), '_blank')

export const fetchAdminTranslations = (entity, entityId) => req(`/admin/translate/${entity}/${entityId}`)

export const saveAdminTranslations = (entity, entityId, translations) => req(`/admin/translate/${entity}/${entityId}`, translations)

export const translate = (params) => req('/admin/translate', params)

export const exportQuizPlayer = (id, lang, filename) => req(`/admin/player/export/${id}/${lang}`, null, null, filename)

export const exportPdf = (id, lang, filename) => req(`/admin/player/print/${id}/${lang}`, null, null, filename)

export const exportPdfNs = (id, lang, filename) => req(`/admin/player/printns/${id}/${lang}`, null, null, filename)

export const fetchAdminPlayerExports = (quizId) => req(`/admin/quiz/${quizId}/exports`)

export const createAdminPlayerExport = (quizId, playerExport) => req(`/admin/quiz/${quizId}/exports`, playerExport)

export const fetchAdminPlayerExport = (playerExport) => req(`/admin/exports/${playerExport.id}`)

export const saveAdminPlayerExport = (playerExport) => req(`/admin/exports/${playerExport.id}`, playerExport)

export const deleteAdminPlayerExport = (playerExport) => req(`/admin/exports/${playerExport.id}/delete`, playerExport)

export const fetchAdminQuizChapters = (quizId) => req(`/admin/quiz/${quizId}/chapters`)

export const fetchAdminLeaders = () => req('/admin/leaders')

// super admin

export const fetchSuperAdminClients = () => req('/super/clients')

export const fetchSuperAdminClient = (id) => req(`/super/client/${id}`)

export const saveSuperAdminClient = (client) => req(`/super/client/${client.id}`, client)

export const deleteSuperAdminClient = (client) => req(`/super/client/${client.id}/delete`, client)

export const createSuperAdminClient = (client) => req('/super/create', client)

export const deleteSuperAdminUser = (user) => req(`/super/user/${user.id}/delete`, user)

export const switchSuperAdminClient = (clientId) => req('/super/switch', { id: clientId })

export const createMetabasePassword = (clientId) => req('/super/create-metabase-password', { id: clientId })

export const syncMetabase = () => req('/super/sync-metabase', {})

export const statusMetabase = () => req('/super/metabase-status')

export const copyMetabaseConfig = (clientId) => req('/super/copy-metabase-config', { id: clientId })

export const fetchSuperAdminContracts = () => req('/super/contracts')

export const fetchSuperAdminContract = (id) => req(`/super/contracts/${id}`)

export const saveSuperAdminContract = (contract) => req(`/super/contracts/${contract.id}`, contract)

export const createSuperAdminContract = (contract) => req('/super/contracts', contract)

export const fetchSuperAdminAvatars = () => req('/super/avatars')

export const saveSuperAdminAvatarPoses = (poses, values) => req('/super/avatars', { poses, values })

export const fetchSuperBackups = () => req('/super/backups')

export const fetchSuperStatus = () => req('/super/status')

export const fetchSuperLog = () => req('/super/log')

export const impersonate = (id) => req(`/super/impersonate/${id}`)

export const createTokenUsers = (clientId, count, length, alphabet) => req('/super/tokens', {
  client: clientId, count, length, alphabet,
})
