/*
 *
 * Quiz constants
 *
 */

export const SUBMIT_ACTION = 'app/Quiz/SUBMIT_ACTION'
export const SELECT_ANSWER_ACTION = 'app/Quiz/SELECT_ANSWER_ACTION'
export const TICK_ACTION = 'app/Quiz/TICK_ACTION'
export const NEXT_ACTION = 'app/Quiz/NEXT_ACTION'
export const PREV_ACTION = 'app/Quiz/PREV_ACTION'
export const NEXT_INDEX_ACTION = 'app/Quiz/NEXT_INDEX_ACTION'
export const PREV_INDEX_ACTION = 'app/Quiz/PREV_INDEX_ACTION'
export const GOTO_INDEX_ACTION = 'app/Quiz/GOTO_INDEX_ACTION'
export const SHOW_SOLUTION_ACTION = 'app/Quiz/SHOW_SOLUTION_ACTION'
export const SHOW_RESULT_ACTION = 'app/Quiz/SHOW_RESULT_ACTION'
export const SURRENDER_ACTION = 'app/Quiz/SURRENDER_ACTION'
export const SHOW_SURRENDER_WARNING_ACTION = 'app/Quiz/SHOW_SURRENDER_WARNING_ACTION'
export const HIDE_SURRENDER_WARNING_ACTION = 'app/Quiz/HIDE_SURRENDER_WARNING_ACTION'
export const EVALUATE_ACTION = 'app/Quiz/EVALUATE_ACTION'

export const CREATE_TRAINING_QUIZ_ACTION = 'app/Quiz/CREATE_TRAINING_QUIZ_ACTION'
export const CREATE_CHALLENGE_QUIZ_ACTION = 'app/Quiz/CREATE_CHALLENGE_QUIZ_ACTION'
export const CREATE_USER_CHALLENGE_QUIZ_ACTION = 'app/Quiz/CREATE_USER_CHALLENGE_QUIZ_ACTION'
export const ACCEPT_USER_CHALLENGE_QUIZ_ACTION = 'app/Quiz/ACCEPT_USER_CHALLENGE_QUIZ_ACTION'

export const QUIZ_FETCH_REQUESTED = 'app/Quiz/QUIZ_FETCH_REQUESTED'
export const QUIZ_FETCH_SUCCEEDED = 'app/Quiz/QUIZ_FETCH_SUCCEEDED'
export const QUIZ_FETCH_FAILED = 'app/Quiz/QUIZ_FETCH_FAILED'

export const QUIZ_RESET_REQUESTED = 'app/Quiz/QUIZ_RESET_REQUESTED'

export const QUIZ_SAVE_EVALUATIONS_REQUESTED = 'app/Quiz/QUIZ_SAVE_EVALUATIONS_REQUESTED'
export const QUIZ_SAVE_EVALUATIONS_SUCCEEDED = 'app/Quiz/QUIZ_SAVE_EVALUATIONS_SUCCEEDED'
export const QUIZ_SAVE_EVALUATIONS_FAILED = 'app/Quiz/QUIZ_SAVE_EVALUATIONS_FAILED'

export const RESET_QUIZ = 'app/Quiz/RESET_QUIZ'

export const FINISHED_INFO_ACTION = 'app/Quiz/FINISHED_INFO_ACTION'
