export default function createReducer(initialState, handlers) {
  if (handlers.undefined) {
    console.warn( // eslint-disable-line
      'Reducer contains an \'undefined\' action type. '
      + 'Have you misspelled a constant?'
    )
  }

  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) { // eslint-disable-line
      return handlers[action.type](state, action)
    }
    return state
  }
}
