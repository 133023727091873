import React from 'react'
import styled from 'styled-components'
import Star from '../../components/Icons/Star/index.js'
import InventoryItemCount from '../../components/InventoryItemCount/index.js'

const TextStar = styled(Star)`
  display: inline-flex;
`

const starSize = 16

// ## XP item notifications
const xpItemType = {

  // item vs other player
  won(notification) {
    return notification.data.value > 0 ? {
      type: 'info',
      style: 'won',
      messageId: 'notification.xp.item.won',
      messageDefault: 'Received {value} {star} in quiz {quiz} for beating {opponent} in question {question}!',
      messageValues: {
        value: <b>{notification.data.value}</b>,
        star: <TextStar size={starSize} />,
        quiz: <b>{notification.data.quiz.name}</b>,
        question: <b>{`#${notification.data.item.index + 1}`}</b>,
        opponent: <b>{notification.data.opponent.nickname}</b>,
      },
    } : null
  },

  // for correct answer
  correct(notification) {
    return notification.data.value > 0 ? {
      type: 'info',
      style: 'success',
      messageId: 'notification.xp.item.correct',
      messageDefault: 'Received {value} {star} in quiz {quiz} for correctly answering question {question}!',
      messageValues: {
        value: <b>{notification.data.value}</b>,
        star: <TextStar size={starSize} />,
        quiz: <b>{notification.data.quiz.name}</b>,
        question: <b>{`#${notification.data.item.index + 1}`}</b>,
      },
    } : null
  },

  // for first time correctly answering
  first(notification) {
    return notification.data.value > 0 ? {
      type: 'info',
      style: 'success',
      messageId: 'notification.xp.item.first',
      messageDefault: 'Received {value} {star} in quiz {quiz} for first time correctly answering question {question}!',
      messageValues: {
        value: <b>{notification.data.value}</b>,
        star: <TextStar size={starSize} />,
        quiz: <b>{notification.data.quiz.name}</b>,
        question: <b>{`#${notification.data.item.index + 1}`}</b>,
      },
    } : null
  },

  // not type found
  default(notification) {
    return notification.data.value > 0 ? {
      type: 'info',
      style: 'success',
      messageId: 'notification.xp.item.default',
      messageDefault: 'Received {value} {star} in quiz {quiz} for question {question}!',
      messageValues: {
        value: <b>{notification.data.value}</b>,
        star: <TextStar size={starSize} />,
        quiz: <b>{notification.data.quiz.name}</b>,
        question: <b>{`#${notification.data.item.index + 1}`}</b>,
      },
    } : null
  },
}

// ## XP notifications
const xpType = {
  item(notification) {
    if (xpItemType[notification.data.type]) {
      return xpItemType[notification.data.type](notification)
    }
    return xpItemType.default(notification)
  },
  quiz(notification) {
    return notification.data.value > 0 ? {
      type: 'info',
      style: 'success',
      messageId: 'notification.xp.quiz',
      messageDefault: 'Received {value} {star} in quiz {quiz}!',
      messageValues: {
        value: <b>{notification.data.value}</b>,
        star: <TextStar size={starSize} />,
        quiz: <b>{notification.data.quiz.name}</b>,
      },
    } : null
  },
  default(notification) {
    return notification.data.value > 0 ? {
      type: 'info',
      style: 'success',
      messageId: 'notification.xp.default',
      messageDefault: 'Received {value} {star}!',
      messageValues: {
        value: <b>{notification.data.value}</b>,
        star: <TextStar size={starSize} />,
      },
    } : null
  },
}

// ## Quiz notifications
const quizType = {
  // won is a xpType

  // lost a quiz
  lost(notification) {
    return {
      type: 'info',
      style: 'lost',
      messageId: 'notification.quiz.lost',
      messageDefault: 'Lost quiz {quiz} against {opponent}!',
      messageValues: {
        quiz: <b>{notification.data.quiz.name}</b>,
        opponent: <b>{notification.data.opponent.nickname}</b>,
        star: <TextStar size={starSize} />,
      },
    }
  },

  won(notification) {
    return {
      type: 'info',
      style: 'success',
      icon: 'trophy',
      titleId: 'notification.quiz.won.title',
      titleDefault: 'VICTORY!',
      messageId: 'notification.quiz.won',
      messageDefault: 'You won the quiz {quiz} against {opponent}!',
      messageValues: {
        quiz: <b>{notification.data.quiz.name}</b>,
        opponent: <b>{notification.data.opponent.nickname}</b>,
        star: <TextStar size={starSize} />,
      },
    }
  },

  default(notification) {
    return {
      type: 'info',
      style: 'success',
      messageId: 'notification.quiz.default',
      messageDefault: '{quiz}!',
      messageValues: {
        quiz: <b>{notification.data.quiz.name}</b>,
        star: <TextStar size={starSize} />,
      },
    }
  },
}

// # Notifications
const type = {
  quest(notification) {
    return {
      type: 'info',
      style: 'success',
      messageId: 'notification.quest.done',
      messageDefault: 'Completed {quest}!{list}',
      messageValues: {
        quest: <b>{notification.data.quest.name}</b>,
        star: <TextStar size={starSize} />,
        list: (
          <ul style={{ padding: '1rem', margin: 0, listStyleType: 'none' }}>
            {notification.data.items.map((i) => (
              <li
                key={i.item.id}
                style={{ margin: 0 }}
              >
                <InventoryItemCount item={i.item} count={i.count} size={32} />
              </li>
            ))}
          </ul>
        ),
      },
    }
  },
  xp(notification) {
    if (notification.data) {
      if (notification.data.item) {
        return xpType.item(notification)
      } if (notification.data.quiz) {
        return xpType.quiz(notification)
      }
      return xpType.default(notification)
    }
    // no data
    return {
      type: 'info',
      style: 'success',
      messageId: 'notification.xp.novalue',
      messageDefault: 'Received XP!',
    }
  },
  skill(notification) {
    return {
      type: 'info',
      style: 'success',
      messageId: 'notification.skill',
      messageDefault: 'Received new skill points!{list}',
      messageValues: {
        list: (
          <ul style={{ padding: '1rem', margin: 0, listStyleType: 'none' }}>
            {notification.data.skills.map((skill) => (
              <li
                key={skill.id}
                style={{ margin: 0 }}
              >
                <b>
                  +
                  {skill.count || 1}
                </b>
                {' '}
                {skill.name}
              </li>
            ))}
          </ul>
        ),
      },
    }
  },
  quiz(notification) {
    if (quizType[notification.data.type]) {
      return quizType[notification.data.type](notification)
    }
    return quizType.default(notification)
  },
  challenge(notification) {
    return {
      type: 'info',
      style: 'success',
      messageId: 'notification.challenge',
      messageDefault: 'Player {opponent} has challenged you in {quiz}',
      messageValues: {
        quiz: <b>{notification.data.quiz.name}</b>,
        opponent: <b>{notification.data.opponent.nickname}</b>,
      },
    }
  },
}

export const getToastyForNotification = (notification) => {
  if (type[notification.type]) {
    return type[notification.type](notification)
  }
  console.error('no toasty for notification', notification) // eslint-disable-line
  return null
}
