import { createSelector } from 'reselect'

// selectLocationState expects a plain JS object for the routing state
const selectLocationState = () => {
  let prevRoutingState
  let prevRoutingStateJS

  return (state) => {
    const routingState = state.get('route') // or state.route

    if (!routingState.equals(prevRoutingState)) {
      prevRoutingState = routingState
      prevRoutingStateJS = routingState.toJS()
    }

    return prevRoutingStateJS
  }
}

/**
 * Direct selector to the quizList state domain
 */

const selectAppDomain = () => (state) => state.get('app')
const selectAppSize = () => (state) => state.get('app').get('size')
const selectIsMobile = () => (state) => {
  const appSize = state.get('app').get('size').toJS()
  const os = state.get('app').get('os')

  if (appSize.phone && appSize.portrait) {
    return true
  }

  if (appSize.phone && os === 'mobile') {
    return true
  }

  return false
}
const selectAppRoute = () => (state) => state.get('app').get('route')
const selectAppReturnPath = () => (state) => state.get('app').get('returnPath')
const selectAppFeatures = () => (state) => state.get('app').get('features')
const selectAppContracts = () => (state) => state.get('app').get('contracts')
const selectAppContractLoading = () => (state) => state.get('app').get('contractsLoading')
const selectLanguage = () => (state) => state.get('language')
const selectLanguages = () => (state) => state.get('app').get('languages')
const selectProfileLanguage = () => (state) => state.get('app').get('profile').get('language')
const selectAvatar = () => (state) => state.get('login').get('avatar')

const selectInventoryList = () => (state) => state.get('app').get('inventory')
const selectInventoryOpen = () => (state) => state.get('app').get('inventoryOpen')
const selectInventorySelected = () => (state) => state.get('app').get('inventorySelectedItem')
const selectInventoryPage = () => (state) => state.get('app').get('inventoryPage')

const selectHasRealname = () => (state) => (state.get('login').get('hasRealname'))
const selectHasCountry = () => (state) => (state.get('login').get('hasCountry'))
const selecthasGender = () => (state) => (state.get('login').get('hasGender'))
/**
 * Other specific selectors
 */

/**
 * Default selector used by QuizList
 */

const selectApp = () => createSelector(
  selectAppDomain(),
  selectLanguage(),
  selectAvatar(),
  selectIsMobile(),
  (substate, language, avatar, isMobile) => substate.merge({ language, avatar, isMobile }).toJS()
)

const selectAppRealname = () => createSelector(
  selectAppDomain(),
  selectLanguage(),
  selectAvatar(),
  selectIsMobile(),
  selectHasRealname(),
  selectHasCountry(),
  selecthasGender(),
  (substate, language, avatar, isMobile, hasRealname, hasCountry, hasGender) => substate.merge({
    language, avatar, isMobile, hasRealname, hasCountry, hasGender,
  }).toJS()
)

const selectInventory = () => createSelector(
  selectInventoryList(),
  selectInventoryOpen(),
  selectInventorySelected(),
  selectInventoryPage(),
  (inventory, open, selected, page) => ({
    list: inventory.toJS(),
    open,
    page,
    selected: selected ? selected.toJS() : null,
  })
)

export default selectApp
export {
  selectIsMobile,
  selectAppSize,
  selectAppRoute,
  selectLocationState,
  selectAppReturnPath,
  selectApp,
  selectAppFeatures,
  selectAppContracts,
  selectAppContractLoading,
  selectAppDomain,
  selectLanguage,
  selectAvatar,
  selectInventory,
  selectProfileLanguage,
  selectLanguages,
  selectAppRealname, // profile
}
