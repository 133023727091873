/*
 *
 * Sound reducer
 *
 */

import { fromJS } from 'immutable'
import { RESET } from '../App/constants.js'
import createReducer from '../../createReducer.js'
import {
  PLAY_ACTION,
} from './constants.js'

const initialState = fromJS({
  history: [],
})

export const reducer = createReducer(initialState, {

  [RESET]() {
    return initialState
  },

  [PLAY_ACTION](state, action) {
    return state.merge({
      history: state.get('history').push(action.name),
    })
  },

})

export default reducer
