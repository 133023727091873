/*
 *
 * LoginPage actions
 *
 */

import {
  LOGIN_REQUESTED,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGIN_UPDATE_AVATAR,
  LOGIN_UPDATE_SLOGAN,
  LOGIN_UPDATE_NICKNAME,
  LOGIN_FAILED_RESET,
  LOGIN_UPDATE_SOUNDS,
  LOGIN_UPDATE_SHOW_AVATAR_SELECTION,
  LOGIN_ENABLE_LTI,
  LOGIN_SET_AVATAR_SELECTED,
} from './constants.js'

export function login(credentials, options) {
  return {
    type: LOGIN_REQUESTED,
    credentials,
    options,
  }
}

export function loginSucceeded(res, client) {
  return {
    ...res,
    type: LOGIN_SUCCEEDED,
    client,
  }
}

export function loginFailed(message) {
  return {
    type: LOGIN_FAILED,
    message,
  }
}

export function loginFailedReset() {
  return {
    type: LOGIN_FAILED_RESET,
  }
}

export function loginSetAvatarSelected(value) {
  return {
    type: LOGIN_SET_AVATAR_SELECTED,
    value: !!value,
  }
}

export function loginUpdateAvatar(avatar) {
  return {
    type: LOGIN_UPDATE_AVATAR,
    avatar,
  }
}

export function loginUpdateShowAvatarSelection(showAvatarSelection) {
  return {
    type: LOGIN_UPDATE_SHOW_AVATAR_SELECTION,
    showAvatarSelection,
  }
}

export function loginUpdateSlogan(slogan) {
  return {
    type: LOGIN_UPDATE_SLOGAN,
    slogan,
  }
}

export function loginUpdateNickname(nickname) {
  return {
    type: LOGIN_UPDATE_NICKNAME,
    nickname,
  }
}

export function loginUpdateSounds(sounds) {
  return {
    type: LOGIN_UPDATE_SOUNDS,
    sounds,
  }
}

export function enableLTI() {
  return {
    type: LOGIN_ENABLE_LTI,
  }
}
