/*
 *
 * Header actions
 *
 */

import {
  SHOW_ACTION,
  HIDE_ACTION,
  NOTIFICATIONS_FETCH_REQUESTED,
  LOGOUT_REQUESTED,
  HIGHLIGHT,
  CLEAR_HIGHLIGHT,
} from './constants.js'

export function showHeaderAction() {
  return {
    type: SHOW_ACTION,
  }
}

export function hideHeaderAction() {
  return {
    type: HIDE_ACTION,
  }
}

export function fetchNotifications() {
  return {
    type: NOTIFICATIONS_FETCH_REQUESTED,
  }
}

export function logout() {
  return {
    type: LOGOUT_REQUESTED,
  }
}

export function highlightHeaderItem(menuItem) {
  return {
    type: HIGHLIGHT,
    highlight: menuItem,
  }
}

export function clearhighlight() {
  return {
    type: CLEAR_HIGHLIGHT,
  }
}
