import localforage from 'localforage'

export const configureStoreLocalforageMiddleware = () => {
  let lock = false
  const throttelTimeout = 1000
  const keys = ['client', 'login', 'quiz', 'adminLogin']

  return (store) => (next) => (action) => {
    const result = next(action)
    if (!lock) {
      lock = true
      setTimeout(() => {
        lock = false
        const saveState = store.getState()
          .toSeq()
          .filter((data, key) => keys.indexOf(key) >= 0)
          .map((data, key) => {
            // do not store the token on LTI sessions
            if (key === 'login' && data.get('lti')) {
              return data.merge({
                lti: false,
                email: null,
                token: null,
              })
            }
            return data
          })
          .toMap()
          .toJS()
        localforage.setItem('store', saveState)
      }, throttelTimeout)
    }
    return result
  }
}
