const fakeFeatures = {
  backdropfilter: false,
  hiddenscroll: true,
  localstorage: false,
}

try {
  if ('localStorage' in window && window.localStorage !== null) {
    fakeFeatures.localstorage = true
  }
} catch (e) {
  // eslint-disable-next-line no-console
  console.log('localStorage is not avalibale')
}

export default fakeFeatures
