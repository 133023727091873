/*
 *
 * AdminLoginPage reducer
 *
 */

import { fromJS } from 'immutable'
import createReducer from '../../createReducer.js'
import {
  RESET,
  REGENERATE_TOKEN,
} from '../App/constants.js'
import {
  LOGIN_REQUESTED,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGOUT,
  SWITCH_CLIENT,
  LOGIN_CLIENTS,
} from './constants.js'

const initialState = fromJS({
  loading: false,
  success: false,
  error: null,
  token: null,
  client: null,
  reset: false,
  clients: [],
})

export const reducer = createReducer(initialState, {

  [RESET]() {
    return initialState.merge({
      reset: true, // to trigger redirect to admin login
    })
  },

  [LOGOUT]() {
    return initialState
  },

  [LOGIN_CLIENTS](state, action) {
    return state.merge({
      loading: false,
      clients: action.clients,
    })
  },

  [SWITCH_CLIENT](state, action) {
    return state.merge({
      client: action.clientId,
    })
  },

  [REGENERATE_TOKEN](state, action) {
    return state.merge({
      token: action.token,
    })
  },

  [LOGIN_REQUESTED](state) {
    return state.merge({
      loading: true,
      success: false,
      error: null,
    })
  },

  [LOGIN_SUCCEEDED](state, action) {
    const { type, ...params } = action // eslint-disable-line
    return state.merge({
      ...params,
      loading: false,
      success: true,
    })
  },

  [LOGIN_FAILED](state, action) {
    return state.merge({
      loading: false,
      success: false,
      error: action.message,
    })
  },

})

export default reducer
