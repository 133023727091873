const isValidVersion = (versionString) => {
  if (typeof versionString !== 'string') {
    return false
  }
  return versionString.match(/^v(\d+\.\d+\.\d+)-?(\d+)?-?([^\.]+)?$/) || versionString.match(/^v(\d+\.\d+)-?(\d+)?-?([^\.]+)?$/) || versionString.match(/^v(\d+\.\d+)$/) // eslint-disable-line
}

export default function isUpdateAvailable(version) {
  console.log(`update: checking if ${version} is the latest version`) // eslint-disable-line
  return fetch(`/VERSION?t=${new Date().getTime()}`)
    .then((res) => {
      if (res.status === 200) {
        return res.text()
      }
      throw new Error(`server version request status code ${res.status}`)
    }).then((serverVersion) => {
      if (!isValidVersion(serverVersion)) {
        throw new Error(`server version "${serverVersion}" is invalid`)
      }
    console.log(`server version: ${serverVersion}`) // eslint-disable-line
      if (serverVersion.trim() === version.trim()) {
      console.log(`update: is already on latest version ${serverVersion}`) // eslint-disable-line
        return false
      }
    console.log(`update: available`) // eslint-disable-line
      return true
    })
}
