import { Component, createElement } from 'react'
import hoistStatics from 'hoist-non-react-statics'
import invariant from 'invariant'
import createTheme from '../../theme.js'
import shallowEqual from '../../utils/shallowEqual.js'
import { getApi } from '../../api.js'
import clientShape from './shape.js'
import features from '../../modernizr.js'
const Api = getApi()

let create = createTheme
let themeOverwrite = null

const getDisplayName = (WrappedComponent) => WrappedComponent.displayName || WrappedComponent.name || 'Component'

// instances to rerender
const instances = []

const testThemeName = features.localStorage ? localStorage.testTheme : undefined

// check if HMR is enabled
if (module.hot) {
  // accept update of theme dependency
  module.hot.accept('../../theme.js', () => {
    // replace
    create = require('../../theme.js').createTheme // eslint-disable-line
    instances.forEach((i) => {
      i.hasThemeChanged = true // eslint-disable-line
      i.forceUpdate()
    })
  })

  module.hot.accept('../../themes/index.js', () => {
    // replace
    const themes = require('../../themes/index.js') // eslint-disable-line
    if (themes[testThemeName]) {
      themeOverwrite = themes[testThemeName]
    }
    instances.forEach((i) => {
      i.hasThemeChanged = true // eslint-disable-line
      i.forceUpdate()
    })
    console.log(`UPDATE bizquiz.client SET theme = '${JSON.stringify(themeOverwrite)}' where id = '${testThemeName}';`) // eslint-disable-line
  })
}

if (module.hot && process.env.NODE_ENV !== 'production' && testThemeName !== undefined) {
  const themes = require('../../themes/index.js') // eslint-disable-line
  if (themes[testThemeName]) {
    themeOverwrite = themes[testThemeName]
    console.warn(`using themes/${testThemeName}.js`) // eslint-disable-line
    console.log(`UPDATE bizquiz.client SET theme = '${JSON.stringify(themeOverwrite)}' where id = '${testThemeName}';`) // eslint-disable-line
  }
}

export default function clientConnect(WrappedComponent) {
  const connectDisplayName = `ClientConnect(${getDisplayName(WrappedComponent)})`

  class ClientConnect extends Component {
    static propTypes = {
      client: clientShape,
    }

    static contextTypes = {
      client: clientShape,
    }

    constructor(props, context) {
      super(props, context)
      this.client = props.client || context.client
      invariant(
        this.client,
        `Could not find "client" in either the context or
        props of "${connectDisplayName}".
        Either wrap the root component in a <Provider>,
        or explicitly pass "client" as a prop to "${connectDisplayName}".`
      )
      this.haveOwnPropsChanged = true
      this.hasThemeChanged = true
      this.hasClientChanged = true
    }

    componentDidMount() {
      // register for hot reloading
      if (module.hot && process.env.NODE_ENV !== 'production') {
        instances.push(this)
      }
    }

    componentWillReceiveProps(nextProps) {
      if (!shallowEqual(nextProps, this.props)) {
        this.haveOwnPropsChanged = true
      }
    }

    shouldComponentUpdate() {
      return !!this.haveOwnPropsChanged
    }

    componentWillUnmount() {
      // deregister for hot reloading
      if (module.hot && process.env.NODE_ENV !== 'production') {
        const index = instances.indexOf(this)
        if (index >= 0) {
          instances.splice(index, 1)
        }
      }
    }

    render() {
      this.haveOwnPropsChanged = false
      if (this.hasClientChanged) {
        // add client dependend recalc
        this.hasClientChanged = false
        this.hasThemeChanged = true
      }
      if (this.hasThemeChanged) {
        this.hasThemeChanged = false
        if (themeOverwrite) {
          this.theme = create(themeOverwrite)
        } else {
          this.theme = create(this.client.theme)
        }
      }
      return createElement(WrappedComponent, {
        ...this.props,
        client: this.client,
        theme: this.theme,
        getUploadUrl: Api.getUploadUrl,
        getApiImage: Api.getApiImage,
        getCrestImage: Api.getCrestImage,
      })
    }
  }

  ClientConnect.displayName = connectDisplayName

  return hoistStatics(ClientConnect, WrappedComponent)
}
