import { breakpoints } from './breakpoints.js'

const supportsCSS = !!(window.CSS && window.CSS.supports)

export const getTopInset = () => {
  if (!supportsCSS) return 0
  let proceed = false
  const div = document.createElement('div')
  if (CSS.supports('padding-top: env(safe-area-inset-top)')) {
    div.style.paddingBottom = 'env(safe-area-inset-top)'
    proceed = true
  } else if (CSS.supports('padding-top: constant(safe-area-inset-top)')) {
    div.style.paddingBottom = 'constant(safe-area-inset-top)'
    proceed = true
  }
  if (proceed) {
    document.body.appendChild(div)
    const calculatedPadding = parseInt(window.getComputedStyle(div).paddingBottom, 10)
    document.body.removeChild(div)
    if (calculatedPadding > 0) {
      return calculatedPadding
    }
  }
  return 0
}

export const getBottomInset = () => {
  if (!supportsCSS) return 0
  let proceed = false
  const div = document.createElement('div')
  if (CSS.supports('padding-top: env(safe-area-inset-bottom)')) {
    div.style.paddingBottom = 'env(safe-area-inset-bottom)'
    proceed = true
  } else if (CSS.supports('padding-top: constant(safe-area-inset-bottom)')) {
    div.style.paddingBottom = 'constant(safe-area-inset-bottom)'
    proceed = true
  }
  if (proceed) {
    document.body.appendChild(div)
    const calculatedPadding = parseInt(window.getComputedStyle(div).paddingBottom, 10)
    document.body.removeChild(div)
    if (calculatedPadding > 0) {
      return calculatedPadding
    }
  }
  return 0
}

export const getDimensions = () => {
  const { documentElement } = window
  const body = document.getElementsByTagName('body')[0]
  const width = window.innerWidth || documentElement.clientWidth || body.clientWidth
  const height = body.clientHeight || window.innerHeight || documentElement.clientHeight
  return { width, height }
}

export const getScrollBarWidth = () => {
  const inner = document.createElement('p')
  inner.style.width = '100%'
  inner.style.height = '200px'

  const outer = document.createElement('div')
  outer.style.position = 'absolute'
  outer.style.top = '0px'
  outer.style.left = '0px'
  outer.style.visibility = 'hidden'
  outer.style.width = '200px'
  outer.style.height = '150px'
  outer.style.overflow = 'hidden'
  outer.appendChild(inner)

  document.body.appendChild(outer)
  const w1 = inner.offsetWidth
  outer.style.overflow = 'scroll'
  let w2 = inner.offsetWidth
  if (w1 === w2) w2 = outer.clientWidth

  document.body.removeChild(outer)

  return (w1 - w2)
}

export const getSize = (prev) => {
  const dimensions = getDimensions()
  const topInset = getTopInset()
  const bottomInset = getBottomInset()
  dimensions.height -= (topInset + bottomInset)

  let b = 'desktop'
  if (
    dimensions.width < breakpoints.tablet.width
    || dimensions.height < breakpoints.tablet.height
  ) {
    b = 'phone'
  } else if (
    dimensions.width < breakpoints.desktop.width
    || dimensions.height < breakpoints.desktop.height
  ) {
    b = 'tablet'
  }

  let portrait = (dimensions.width <= dimensions.height)
  let landscape = (dimensions.width > dimensions.height)

  if (
    prev
    && prev.portrait
    && landscape
    && b === 'phone'
  ) {
    // oriantation has change on phone
    if (prev.dimensions.width === dimensions.width) {
      // Android keyboard detected
      // Do not switch to portrait mode
      landscape = false
      portrait = true
      dimensions.height = prev.dimensions.height
    }
  }

  const size = {
    ...(prev || {}),
    dimensions,
    topInset,
    bottomInset,
    phone: b === 'phone',
    tablet: b === 'tablet',
    desktop: b === 'desktop',
    portrait,
    landscape,
    scrollBarWidth: getScrollBarWidth(),
  }

  return size
}
