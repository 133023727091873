/*
 *
 * Quiz reducer
 *
 */

import { fromJS } from 'immutable'
import createReducer from '../../createReducer.js'
import { RESET } from '../App/constants.js'
import {
  SUBMIT_ACTION,
  SELECT_ANSWER_ACTION,
  TICK_ACTION,
  SHOW_SOLUTION_ACTION,
  SHOW_RESULT_ACTION,
  NEXT_INDEX_ACTION,
  PREV_INDEX_ACTION,
  GOTO_INDEX_ACTION,
  EVALUATE_ACTION,
  QUIZ_FETCH_SUCCEEDED,
  QUIZ_FETCH_FAILED,
  QUIZ_RESET_REQUESTED,
  SURRENDER_ACTION,
  SHOW_SURRENDER_WARNING_ACTION,
  HIDE_SURRENDER_WARNING_ACTION,
  QUIZ_SAVE_EVALUATIONS_REQUESTED,
  QUIZ_SAVE_EVALUATIONS_SUCCEEDED,
  QUIZ_SAVE_EVALUATIONS_FAILED,
  RESET_QUIZ,
  QUIZ_FETCH_REQUESTED,
  FINISHED_INFO_ACTION,
} from './constants.js'

const initialState = fromJS({
  loaded: false,
  error: null,
  hasResultSeen: false,
  startTimestamp: null,
  scene: 'start', // start, quiz or result
  surrenderWarningVisible: false,
  saved: false,
  index: 0, // index of the item
  review: false, // review mode
  context: null, // quiz mode
  item: null,
  my: {},
  enemy: {},
  enemyKnown: false,
  evaluations: [], // filled by reducer
})

const validInfo = (info) => {
  if (!info) {
    return false
  }
  return (info.title || info.content || info.image || info.video)
}

// helper: get ui state for an item by index
// used in next and prev
const getUIItemByIndex = (state, index) => {
  const nextItem = state.get('items').get(index)
  const evaluation = state.get('evaluations').find((e) => e.get('id') === nextItem.get('id'))
  let answers = nextItem.get('answers')
  if (evaluation) {
    answers = answers.map((a) => a.merge({
      selected: evaluation.get('selected').includes(a.get('id')),
    }))
  }
  // add the ui state to the item
  return nextItem.merge({
    showSolution: !!evaluation,
    answers,
    maxTime: nextItem.get('time'),
    showInfo: validInfo(nextItem.get('info').toJS()),
  })
}

export const reducer = createReducer(initialState, {

  [RESET]() {
    return initialState
  },

  [RESET_QUIZ]() {
    return initialState
  },

  [QUIZ_FETCH_REQUESTED](state) {
    return state.merge({
      loaded: false,
      error: null,
    })
  },

  [QUIZ_SAVE_EVALUATIONS_REQUESTED](state) {
    return state.merge({
      saved: false,
    })
  },

  [QUIZ_SAVE_EVALUATIONS_SUCCEEDED](state, action) {
    return state.merge({
      saved: true,
      result: action.result,
    })
  },

  [QUIZ_SAVE_EVALUATIONS_FAILED](state) {
    // TODO: handle error
    return state.merge({
      saved: false,
    })
  },

  [SHOW_SURRENDER_WARNING_ACTION](state) {
    return state.merge({
      surrenderWarningVisible: true,
    })
  },

  [HIDE_SURRENDER_WARNING_ACTION](state) {
    return state.merge({
      surrenderWarningVisible: false,
    })
  },

  [QUIZ_RESET_REQUESTED]() {
    return initialState
  },

  [SURRENDER_ACTION]() {
    return initialState
  },

  [QUIZ_FETCH_SUCCEEDED](state, action) {
    if (!action.quiz.items || !action.quiz.items.length) {
      throw new Error('Server returned no questions')
    }

    return state.merge({
      xpTimeDependendRatio: 0.5,
      ...action.quiz,
      item: {
        ...action.quiz.items[state.get('index')],
        showSolution: false,
        maxTime: action.quiz.items[state.get('index')].time,
        showInfo: validInfo(action.quiz.items[state.get('index')].info),
      },
      loaded: true,
      scene: action.quiz.result ? 'result' : 'start',
      hasResultSeen: !!action.quiz.result,
      review: !!action.quiz.result,
      saved: !!action.quiz.result,
    })
  },

  [QUIZ_FETCH_FAILED](state, action) {
    return state.merge({
      loaded: false,
      error: action.message,
    })
  },

  [SHOW_RESULT_ACTION](state) {
    return state.merge({
      hasResultSeen: true,
      scene: 'result',
    })
  },

  [SUBMIT_ACTION](state) {
    return state
  },

  [SELECT_ANSWER_ACTION](state, action) {
    const item = state.get('item')
    const type = item.get('type')

    const types = {
      single: () => state.merge({
        item: item.merge({
          answers: item.get('answers').map((answer) => answer.merge({
            selected: (action.answer.id === answer.get('id')),
          })),
        }),
      }),
      multiple: () => state.merge({
        item: item.merge({
          answers: item.get('answers').map((answer) => answer.merge({
            selected: (action.answer.id === answer.get('id'))
              ? !answer.get('selected')
              : answer.get('selected')
            ,
          })),
        }),
      }),
    }

    if (types[type]) {
      return types[type]()
    }

    throw new Error(`Quiz reducer can not handle select answer of type ${type}`)
  },

  [EVALUATE_ACTION](state) {
    const missed = state.get('item').get('answers')
      .filter((answer) => !answer.get('selected') && answer.get('correct'))
      .map((answer) => answer.get('id'))
      .toJS()
    const correct = state.get('item').get('answers')
      .filter((answer) => answer.get('selected') && answer.get('correct'))
      .map((answer) => answer.get('id'))
      .toJS()
    const selected = state.get('item').get('answers')
      .filter((answer) => answer.get('selected'))
      .map((answer) => answer.get('id'))
      .toJS()

    let xp = 0
    // if item is correct add the XPs
    if (!missed.length && correct.length === selected.length) {
      const timeLeft = state.get('item').get('time')
      const timeMax = state.get('items').get(state.get('index')).get('time')
      const timeFactor = (timeLeft / timeMax)
      // fixed XP part
      const fixedXP = state.get('item').get('xp') * (1 - state.get('xpTimeDependendRatio'))
      // variable XP part
      const timingXP = state.get('item').get('xp') * state.get('xpTimeDependendRatio') * timeFactor
      xp = Math.round(fixedXP + timingXP)
    }

    const evaluation = fromJS({
      id: state.get('item').get('id'),
      selected,
      missed,
      correct,
      xp,
    })

    return state.merge({
      evaluations: state.get('evaluations').push(evaluation),
    })
  },

  [TICK_ACTION](state) {
    const showSolution = state.get('item').get('showSolution')
    const showInfo = state.get('item').get('showInfo')

    // if we show the solution or info to not tick
    if (showSolution || showInfo) {
      return state
    }

    let newTime = state.get('item').get('time')
    if (newTime > 0) {
      newTime--
    }

    return state.merge({
      item: state.get('item').merge({
        time: newTime,
      }),
    })
  },

  [SHOW_SOLUTION_ACTION](state) {
    return state.merge({
      item: state.get('item').merge({
        showSolution: true,
      }),
    })
  },

  [FINISHED_INFO_ACTION](state) {
    return state.merge({
      item: state.get('item').merge({
        showInfo: false,
      }),
    })
  },

  [NEXT_INDEX_ACTION](state) {
    return {
      start: () => state.merge({
        scene: 'quiz',
        startTimestamp: state.get('startTimestamp') || Math.round((new Date()).getTime() / 1000),
      }),
      quiz: () => {
        const nextIndex = state.get('index') + 1
        if (nextIndex >= state.get('items').size) {
          // test finished TODO: check in saga
          throw new Error('quiz questions out of bounds')
        }

        // add the ui state to the item
        return state.merge({
          index: nextIndex,
          item: getUIItemByIndex(state, nextIndex),
        })
      },
      result: () => {
        throw new Error('next on results')
      },
    }[state.get('scene')]()
  },

  [PREV_INDEX_ACTION](state) {
    return {
      start: () => {
        throw new Error('can not go back')
      },
      quiz: () => {
        const nextIndex = state.get('index') - 1
        if (nextIndex < 0) {
          // test finished TODO: check in saga
          throw new Error('quiz questions out of bounds')
        }

        // add the ui state to the item
        return state.merge({
          index: nextIndex,
          item: getUIItemByIndex(state, nextIndex),
        })
      },
      result: () => {
        const index = state.get('items').size - 1
        return state.merge({
          scene: 'quiz',
          index,
          item: getUIItemByIndex(state, index),
        })
      },
    }[state.get('scene')]()
  },

  [GOTO_INDEX_ACTION](state, action) {
    return {
      start: () => {
        throw new Error('can not goto question on start scene')
      },
      quiz: () => state.merge({
        index: action.index,
        item: getUIItemByIndex(state, action.index),
      }),
      result: () => state.merge({
        scene: 'quiz',
        index: action.index,
        item: getUIItemByIndex(state, action.index),
      }),
    }[state.get('scene')]()
  },

})

export default reducer
