import tinycolor from 'tinycolor2'

let cache = null

const createNewTheme = (o) => {
  if (cache) {
    return cache
  }
  const { colors, overwrites, ...opts } = o || {} // eslint-disable-line

  // required colors
  const rawColors = { // eslint-disable-line
    default: '#8BAFC1',
    primary: '#0098F0',
    secondary: '#E91E63',
    success: '#08D59D',
    error: '#FF5454',
    info: '#FFB901',
    white: '#fff',
    black: '#000',
    transparent: 'rgba(0,0,0,0)',
    card: '#0098f0',
    ...colors || {},
  }

  const options = {
    // backgroundImage
    // backgroundImageMobile

    fontFamily: 'Roboto Condensed,sans-serif',
    fontWeight: '300',
    letterSpacing: '1px',
    borderRadius: 30,
    backgroundLightness: 20,
    backgroundDarkLightness: 15,
    backgroundLightLightness: 25,
    appBackgroundLightLightness: 30,
    buttonBorderSize: 1,

    // webkit blur
    backdropFilter: 'blur(10px)',
    backdropAlpha: 0.6,

    // header
    headerBaseHeight: 75,
    headerBaseImage: undefined,
    headerBaseImagePosition: 'left top',
    headerBaseImageSize: 'contain',

    headerMenuHeight: undefined,

    headerSectionImage: undefined,
    headerSectionImagePosition: 'left top',
    headerSectionImageSize: 'contain',
    headerSectionMarginBottom: 0,

    headerImageLeft: 'https://static.bizquiz.cloud/logo-bizquiz-small-inv.svg',
    headerImageLeftPadding: 0,
    headerImageLeftAlign: 'center',
    headerImageLeftHeight: undefined,
    headerImageLeftWidth: undefined,

    headerImageRight: undefined,
    headerImageRightPadding: 0,
    headerImageRightAlign: 'center',
    headerImageRightHeight: undefined,

    // header mobile
    headerMobileFixed: true,
    headerMobileImage: 'https://static.bizquiz.cloud/logo-bizquiz-small-inv.svg',
    headerMobileMenuImage: 'https://static.bizquiz.cloud/mobile-header-background-600.jpg',
    headerMobileBaseHeight: 60,

    bottomMenuHeight: 40,

    ...opts,
  }

  options.headerMobileImageHeight = options.headerMobileImageHeight || options.headerMobileBaseHeight

  // button opts
  options.buttonBorderRadius = options.buttonBorderRadius || options.borderRadius
  options.buttonFontFamily = options.buttonFontFamily || options.fontFamily

  // transform raw to tinycolors
  const c = {}
  Object.keys(rawColors).forEach((key) => {
    c[key] = tinycolor(rawColors[key])
  })

  const { transparent } = c

  // fallbacks
  c.foreground = c.foreground || c.white
  c.foregroundSoft = c.foregroundSoft || c.foreground.clone().setAlpha(0.5)

  c.foregroundDark = c.foregroundDark || c.foreground.clone().darken(5)
  c.foregroundLight = c.foregroundLight || c.foreground.clone().lighten(5)

  const primaryHsl = c.primary.toHsl()
  c.background = c.background || tinycolor(`hsla(${primaryHsl.h}, ${primaryHsl.s}, ${options.backgroundLightness}%, ${primaryHsl.a})`)
  c.backgroundDark = c.backgroundDark || tinycolor(`hsla(${primaryHsl.h}, ${primaryHsl.s}, ${options.backgroundDarkLightness}%, ${primaryHsl.a})`)
  c.backgroundLight = c.backgroundLight || tinycolor(`hsla(${primaryHsl.h}, ${primaryHsl.s}, ${options.backgroundLightLightness}%, ${primaryHsl.a})`)
  c.appBackground = c.appBackground || c.background

  c.formBackground = c.formBackground || c.backgroundLight
  c.formForeground = c.formForeground || c.foregroundLight

  c.formFieldBackground = c.formBackground || c.formBackground
  c.formFieldForeground = c.formFieldForeground || c.formForeground

  // buttons
  c.buttonForeground = c.buttonForeground || c.foreground
  c.buttonDefaultFallback = c.buttonDefaultFallback || c.primary
  c.buttonDisabledFallback = c.buttonDisabledFallback || c.primary.clone().desaturate(90).lighten(15)

  c.buttonDefault = c.buttonDefault || (options.buttonOutline ? c.buttonDefaultFallback : c.buttonForeground)
  c.buttonDefaultBackground = c.buttonDefaultBackground || (options.buttonOutline ? transparent : c.buttonDefaultFallback)
  c.buttonDefaultBorder = c.buttonDefaultBorder || (options.buttonOutline ? c.buttonDefaultFallback : transparent)

  c.buttonPrimary = c.buttonPrimary || (options.buttonOutline ? c.primary : c.buttonForeground)
  c.buttonPrimaryBackground = c.buttonPrimaryBackground || (options.buttonOutline ? transparent : c.primary)
  c.buttonPrimaryBorder = c.buttonPrimaryBorder || (options.buttonOutline ? c.primary : transparent)

  c.buttonAccent = c.buttonAccent || (options.buttonOutline ? c.accent : c.buttonForeground)
  c.buttonAccentBackground = c.buttonAccentBackground || (options.buttonOutline ? transparent : c.accent)
  c.buttonAccentBorder = c.buttonAccentBorder || (options.buttonOutline ? c.accent : transparent)

  c.buttonSuccess = c.buttonSuccess || (options.buttonOutline ? c.success : c.buttonForeground)
  c.buttonSuccessBackground = c.buttonSuccessBackground || (options.buttonOutline ? transparent : c.success.clone().desaturate(15).darken(3))
  c.buttonSuccessBorder = c.buttonSuccessBorder || (options.buttonOutline ? c.success : c.buttonForeground)

  c.buttonError = c.buttonError || (options.buttonOutline ? c.error : c.buttonForeground)
  c.buttonErrorBackground = c.buttonErrorBackground || (options.buttonOutline ? transparent : c.error.clone().desaturate(20).darken(8))
  c.buttonErrorBorder = c.buttonErrorBorder || (options.buttonOutline ? c.error : c.buttonForeground)

  c.buttonDisabled = c.buttonDisabled || (options.buttonOutline ? c.buttonDisabledFallback : c.buttonForeground.clone().lighten(10))
  c.buttonDisabledBackground = c.buttonDisabledBackground || (options.buttonOutline ? transparent : c.buttonDisabledFallback)
  c.buttonDisabledBorder = c.buttonDisabledBorder || (options.buttonOutline ? c.buttonDisabledFallback : transparent)

  c.buttonLink = c.buttonLink || c.primary
  c.buttonLinkDisabled = c.buttonLinkDisabled || c.buttonLink.clone().lighten(28)

  // desktop header
  c.headerSectionBackground = c.headerSectionBackground || c.backgroundDark
  c.headerSectionBackdropBackground = c.headerSectionBackdropBackground || c.headerSectionBackground.clone().setAlpha(options.backdropAlpha)

  c.headerBaseBackground = c.headerBaseBackground || transparent
  c.headerItem = c.headerItem || c.foregroundDark
  c.headerItemBackground = c.headerItemBackground || transparent
  c.headerItemActive = c.headerItemActive || c.foregroundDark
  c.headerItemActiveBackground = c.headerItemActiveBackground || transparent
  c.headerItemHover = c.headerItemHover || c.headerItemActive
  c.headerItemHoverBackground = c.headerItemHoverBackground || c.headerItemActiveBackground

  // mobile header
  c.headerMobileBackground = c.headerMobileBackground || c.backgroundDark
  c.headerMobileBackdropBackground = c.headerMobileBackdropBackground || c.headerMobileBackground.clone().setAlpha(options.backdropAlpha)
  c.headerMobileItem = c.headerMobileItem || c.foregroundDark
  c.headerMobileItemBackground = c.headerMobileItemBackground || transparent
  c.headerMobileItemActive = c.headerMobileItemActive || c.primary
  c.headerMobileItemActiveBackground = c.headerMobileItemActiveBackground || transparent

  // bottom menu
  c.bottomMenuBackground = c.bottomMenuBackground || c.backgroundDark
  c.bottomMenuBackdropBackground = c.bottomMenuBackdropBackground || c.bottomMenuBackground.clone().setAlpha(options.backdropAlpha)
  c.bottomMenu = c.bottomMenu || c.foregroundDark

  // slash
  c.splashLogoBackground = c.splashLogoBackground || c.white

  // quiz
  c.foregroundCorrectSolution = c.foregroundCorrectSolution || c.foreground
  c.imageOverlayBackground = c.imageOverlayBackground || c.background.clone().setAlpha(0.8)

  // icons
  c.starFill = c.starFill || c.info
  c.starFillLight = c.starFillLight || c.starFill.clone().lighten(28)
  c.starFillDark = c.starFillDark || c.starFill.clone().darken(12)
  c.starOutline = c.starOutline || c.foreground

  cache = {
    opts: { ...options, ...c },
    overwrites: overwrites || {},
  }

  return cache
}

function createThemeStyles(name, generator) {
  return function(position) { // eslint-disable-line
    const { overwrites, opts } = this.props.theme // eslint-disable-line
    let overwritePosition = {}
    let overwriteDefault = {}
    if (overwrites && overwrites[name]) {
      if (overwrites[name].default) {
        overwriteDefault = overwrites[name].default
      }
      if (overwrites[name][position]) {
        overwritePosition = overwrites[name][position]
      }
    }

    const overwriteOpts = { ...overwriteDefault, ...overwritePosition }
    Object.keys(overwriteOpts).forEach((key) => {
      if (opts[key] instanceof tinycolor) {
        overwriteOpts[key] = tinycolor(overwriteOpts[key])
      }
    })
    const finalOpts = { ...opts, ...overwriteOpts }
    // fix card color for api
    finalOpts.card = finalOpts.card.toHex().toUpperCase()
    return generator(finalOpts)
  }
}

export default createNewTheme
export {
  createThemeStyles,
}
