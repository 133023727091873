/*
 *
 * Toasty reducer
 *
 */

import { fromJS } from 'immutable'
import createReducer from '../../createReducer.js'
import { RESET } from '../App/constants.js'
import {
  CREATE_ACTION,
  REMOVE_ACTION,
  CREATE_FULLSCREEN,
  CLOSE_FULLSCREEN,
} from './constants.js'

const initialState = fromJS({
  toasties: [],
  fullscreenNotification: null,
})

export const reducer = createReducer(initialState, {

  [RESET]() {
    return initialState
  },

  [CREATE_FULLSCREEN](state, action) {
    return state.merge({
      fullscreenNotification: action.notification,
    })
  },

  [CLOSE_FULLSCREEN](state) {
    return state.merge({
      fullscreenNotification: null,
    })
  },

  [CREATE_ACTION](state, action) {
    return state.merge({
      toasties: state.get('toasties').push(fromJS({
        id: action.id,
        ...action.options,
      })),
    })
  },

  [REMOVE_ACTION](state, action) {
    return state.merge({
      toasties: state.get('toasties').splice(action.index, 1),
    })
  },

})

export default reducer
