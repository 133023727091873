import { PropTypes } from 'prop-types'

export const sizeShape = PropTypes.shape({
  dimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  phone: PropTypes.bool.isRequired,
  tablet: PropTypes.bool.isRequired,
  desktop: PropTypes.bool.isRequired,
  portrait: PropTypes.bool.isRequired,
  landscape: PropTypes.bool.isRequired,
  tight: PropTypes.bool.isRequired,
})

// deprecated use size shape
export const appShape = sizeShape

export default sizeShape
