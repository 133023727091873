import './piwik.js'
import md5 from 'js-md5'

class Tracker {
  constructor(url) {
    this.url = url
    this.storage = []
  }

  startRemoteTracking = () => {
    this.remote = Piwik.getTracker(this.url, 1) // eslint-disable-line
    if (this.storage.length) {
      for (let i = 0; i < this.storage.length; i++) {
        const item = this.storage[i]
        this.remote[item.type](...item.params)
      }
      this.storage = []
    }
  }

  cleanUp = () => {
    if (this.storage.length > 16) {
      this.storage = this.storage.filter((i) => i.type === 'setCustomDimension' || i.type === 'setUserId')
    }
  }

  trackPageView = (customTitle, customData) => {
    if (this.remote) {
      return this.remote.trackPageView(customTitle, customData)
    }

    this.storage.push({ type: 'trackPageView', params: [customTitle, customData] })
    this.cleanUp()
    return false
  }

  trackEvent = (category, action, name, value, customData) => {
    if (this.remote) {
      return this.remote.trackEvent(category, action, name, value, customData)
    }
    this.cleanUp()
    this.storage.push({ type: 'trackEvent', params: [category, action, name, value, customData] })
    return false
  }

  setUserId = (userId) => {
    const hash = md5(userId)
    if (this.remote) {
      return this.remote.setUserId(hash)
    }

    this.storage.push({ type: 'setUserId', params: [hash] })
    return false
  }

  setCustomDimension = (customDimensionId, value) => {
    if (this.remote) {
      return this.remote.setCustomDimension(customDimensionId, value)
    }

    this.storage.push({ type: 'setCustomDimension', params: [customDimensionId, value] })
    return false
  }

  setCustomUrl = (url) => {
    if (this.remote) {
      return this.remote.setCustomUrl(url)
    }

    this.storage.push({ type: 'setCustomUrl', params: [url] })
    return false
  }
}

export default Tracker
