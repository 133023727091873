import { delay } from 'redux-saga'
import {
  call, put, select, take, takeLatest, takeEvery,
} from 'redux-saga/effects'
import {
  RESET,
  ROUTE,
} from '../App/constants.js'
import { getApi } from '../../api.js'
import { getToastyForNotification } from './notifications.js'
const Api = getApi()

import {
  showHeaderAction,
  hideHeaderAction,
  clearhighlight,
} from './actions.js'

import {
  createToastyAction,
  createFullscreenMessage,
} from '../Toasty/actions.js'

import {
  setOnlineUsers,
  setContracts,
  fetchInventory,
} from '../App/actions.js'

import {
  fetchQuests,
} from '../Quests/actions.js'

import {
  NOTIFICATIONS_FETCH_REQUESTED,
  LOGOUT_REQUESTED,
} from './constants.js'

import {
  CLOSE_FULLSCREEN,
} from '../Toasty/constants.js'

function* visibleHeaderWorker() {
  yield put(clearhighlight())
  const state = yield select()
  if (
    state.has('app')
    && state.get('app').has('route')
    && state.get('app').get('route').has('menuVisible')
  ) {
    const visible = state.get('app').get('route').get('menuVisible')
    if (visible !== state.get('header').get('visible')) {
      if (visible) {
        yield put(showHeaderAction())
      } else {
        yield put(hideHeaderAction())
      }
    }
  }
}

const contractCheckInterval = 3600

function* fetchNotificationsWorker() {
  const state = yield select()
  if (
    state.has('app')
    && state.get('app').has('route')
    && state.get('app').get('route').has('receiveNotifications')
    && state.get('app').get('route').get('receiveNotifications')
  ) {
    const contractsUpdateTime = state.get('app').get('contractsUpdateTime')
    const now = Math.round((new Date()).getTime() / 1000)
    try {
      yield delay(500) // wait a little
      const notifications = yield call(Api.fetchNotifications)
      const onlineUsers = yield call(Api.fetchOnlineUsers)
      yield put(setOnlineUsers(onlineUsers))
      if (now > (contractsUpdateTime + contractCheckInterval)) {
        const contracts = yield call(Api.fetchUserContracts)
        yield put(setContracts(contracts))
      }
      // smart update inventory on quest notification
      if (notifications.find((n) => n.type === 'quest')) {
        yield put(fetchInventory())
        yield put(fetchQuests())
      }
      for (let i = 0; i < notifications.length; i++) {
        const notification = notifications[i]
        if (notification.fullscreen) {
          yield put(createFullscreenMessage(notification))
          yield take(CLOSE_FULLSCREEN)
          yield delay(500)
        } else {
          const toastOptions = getToastyForNotification(notification)
          if (toastOptions) {
            yield put(createToastyAction(toastOptions))
            yield delay(2000)
          }
        }
      }
    } catch (e) {
      console.error(e.stack) // eslint-disable-line
    }
  }
}

function* logoutWorker() {
  try {
    yield call(Api.logout)
  } catch (e) {
    console.error(e.stack) // eslint-disable-line
  }
  yield put({ type: RESET })
}

export function* visibleHeaderSaga() {
  yield takeLatest(ROUTE, visibleHeaderWorker)
}

export function* fetchNotificationsSaga() {
  yield takeEvery(NOTIFICATIONS_FETCH_REQUESTED, fetchNotificationsWorker)
}

export function* logoutSaga() {
  yield takeEvery(LOGOUT_REQUESTED, logoutWorker)
}

// All sagas to be loaded
export default [
  visibleHeaderSaga,
  fetchNotificationsSaga,
  logoutSaga,
]
