/*
 *
 * App actions
 *
 */

import {
  ADD_HTML_STYLE,
  SET_DIMENSIONS,
  SET_LOGIN_REDIRECT,
  SET_ROUTE_TRANSITION,
  ROUTE,
  SET_FEATURE,
  SET_ONLINE_USERS,
  SET_RESIZING,
  START_CACHING,
  SET_OS,
  SET_CONTRACTS,
  READ_CONTRACT,
  READ_CONTRACT_SUCCESS,
  RESET,
  SET_LIVE_RESIZE,

  // profile
  PROFILE_FETCH_REQUESTED,
  PROFILE_FETCH_SUCCEEDED,
  PROFILE_FETCH_FAILED,
  PROFILE_TOGGLE_SEND_NOTIFICATIONS,
  PROFILE_TOGGLE_SEND_NEWS,
  PROFILE_TOGGLE_FIND_ME_BY_EMAIL,
  PROFILE_TOGGLE_SOUND,
  PROFILE_SET_SLOGAN,
  PROFILE_SET_NICKNAME,
  PROFILE_SAVED,
  PROFILE_SAVE_REQUESTED,
  PROFILE_DELETE_REQUESTED,
  PROFILE_DELETE_SUCCEEDED,
  PROFILE_DELETE_FAILED,
  PROFILE_TOGGLE_SAVEDELETE,
  PROFILE_RESET_PASSWORD_REQUESTED,
  PROFILE_CHANGE_LANGUAGE,
  PROFILE_CHANGE_SERVER_LANGUAGE,

  INVENTORY_FETCH_REQUESTED,
  INVENTORY_FETCH_SUCCEEDED,
  INVENTORY_FETCH_FAILED,

  INVENTORY_OPEN,
  INVENTORY_CLOSE,

  PROFILE_SET_FIRSTNAME,
  PROFILE_SET_LASTNAME,
  PROFILE_SET_GENDER,
  PROFILE_SET_COUNTRY,
} from './constants.js'

export function setFirstname(value) {
  return {
    type: PROFILE_SET_FIRSTNAME,
    value,
  }
}

export function setLastname(value) {
  return {
    type: PROFILE_SET_LASTNAME,
    value,
  }
}

export function setGender(value) {
  return {
    type: PROFILE_SET_GENDER,
    value,
  }
}

export function setCountry(value) {
  return {
    type: PROFILE_SET_COUNTRY,
    value,
  }
}

export function changeServerLangauge(id) {
  return {
    type: PROFILE_CHANGE_SERVER_LANGUAGE,
    id,
  }
}

export function changeLanguage(id) {
  return {
    type: PROFILE_CHANGE_LANGUAGE,
    id,
  }
}

export function setLiveResize(value) {
  return {
    type: SET_LIVE_RESIZE,
    value: !!value,
  }
}

export function reset() {
  return {
    type: RESET,
  }
}

export function setOS(os) {
  // Dirty Hack to enable os in routes isMobile
  window._os = os // eslint-disable-line
  return {
    type: SET_OS,
    os,
  }
}

export function startCaching() {
  return {
    type: START_CACHING,
  }
}

export function setResizing(resizing) {
  return {
    type: SET_RESIZING,
    resizing,
  }
}

export function addHtmlStyle(style) {
  return {
    type: ADD_HTML_STYLE,
    style,
  }
}

export function setRouteTransition(name) {
  return {
    type: SET_ROUTE_TRANSITION,
    name,
  }
}

export function setFeature(key, value) {
  return {
    type: SET_FEATURE,
    key,
    value,
  }
}

export function setDimensions(dimensions) {
  return {
    type: SET_DIMENSIONS,
    dimensions,
  }
}

export function setLoginRedirect(pathname) {
  return {
    type: SET_LOGIN_REDIRECT,
    pathname,
  }
}

export function pushRoute(route) {
  return {
    type: ROUTE,
    route,
  }
}

export function setOnlineUsers(users) {
  return {
    type: SET_ONLINE_USERS,
    users,
  }
}

export function setContracts(contracts) {
  return {
    type: SET_CONTRACTS,
    contracts,
  }
}

export function readContract(contract, accepted) {
  return {
    type: READ_CONTRACT,
    contract,
    accepted,
  }
}

export function readContractSuccess(contract, accepted) {
  return {
    type: READ_CONTRACT_SUCCESS,
    contract,
    accepted,
  }
}

export function toggleSaveDelete() {
  return {
    type: PROFILE_TOGGLE_SAVEDELETE,
  }
}

export function deleteProfile() {
  return {
    type: PROFILE_DELETE_REQUESTED,
  }
}

export function deleteProfileSucceeded() {
  return {
    type: PROFILE_DELETE_SUCCEEDED,
  }
}

export function deleteProfileFailed(message) {
  return {
    type: PROFILE_DELETE_FAILED,
    message,
  }
}

export function save() {
  return {
    type: PROFILE_SAVE_REQUESTED,
  }
}

export function saved() {
  return {
    type: PROFILE_SAVED,
  }
}

export function toggleSendNews() {
  return {
    type: PROFILE_TOGGLE_SEND_NEWS,
  }
}

export function toggleSounds() {
  return {
    type: PROFILE_TOGGLE_SOUND,
  }
}

export function toggleFindMeByEmail() {
  return {
    type: PROFILE_TOGGLE_FIND_ME_BY_EMAIL,
  }
}

export function toggleSendNotifications() {
  return {
    type: PROFILE_TOGGLE_SEND_NOTIFICATIONS,
  }
}

export function setSlogan(value) {
  return {
    type: PROFILE_SET_SLOGAN,
    value,
  }
}

export function setNickname(value) {
  return {
    type: PROFILE_SET_NICKNAME,
    value,
  }
}

export function fetchProfile() {
  return {
    type: PROFILE_FETCH_REQUESTED,
  }
}

export function fetchProfileSucceeded(profile, languages) {
  return {
    type: PROFILE_FETCH_SUCCEEDED,
    profile,
    languages,
  }
}

export function fetchProfileFailed(message) {
  return {
    type: PROFILE_FETCH_FAILED,
    message,
  }
}

export function resetPassword() {
  return {
    type: PROFILE_RESET_PASSWORD_REQUESTED,
  }
}

export function fetchInventory() {
  return {
    type: INVENTORY_FETCH_REQUESTED,
  }
}

export function fetchInventorySucceeded(inventory) {
  return {
    type: INVENTORY_FETCH_SUCCEEDED,
    inventory,
  }
}

export function fetchInventoryFailed(message) {
  return {
    type: INVENTORY_FETCH_FAILED,
    message,
  }
}

export function openInventory(item) {
  if (typeof item === 'string' || item instanceof String) {
    return {
      type: INVENTORY_OPEN,
      page: item,
      item: null,
    }
  }
  return {
    type: INVENTORY_OPEN,
    page: 'item',
    item,
  }
}

export function closeInventory() {
  return {
    type: INVENTORY_CLOSE,
  }
}
