/*
 *
 * LoginPage reducer
 *
 */

import { fromJS } from 'immutable'
import createReducer from '../../createReducer.js'
import {
  RESET,
  REGENERATE_TOKEN,
} from '../App/constants.js'
import {
  LOGIN_REQUESTED,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGIN_UPDATE_AVATAR,
  LOGIN_UPDATE_SLOGAN,
  LOGIN_FAILED_RESET,
  LOGIN_UPDATE_NICKNAME,
  LOGIN_UPDATE_SOUNDS,
  LOGIN_ENABLE_LTI,
  LOGIN_UPDATE_SHOW_AVATAR_SELECTION,
  LOGIN_SET_AVATAR_SELECTED,
} from './constants.js'

const initialState = fromJS({
  loading: false,
  success: false,
  error: null,
  token: null,
  lti: false,
  version: VERSION, // eslint-disable-line
  options: {},
  avatarSelected: true,
})

export const reducer = createReducer(initialState, {

  [RESET](state) {
    return initialState.merge({
      // only save none fake email
      email: state.get('hasEmail') ? state.get('email') : undefined,
      options: state.get('options'),
    })
  },

  [REGENERATE_TOKEN](state, action) {
    return state.merge({
      token: action.token,
    })
  },

  [LOGIN_REQUESTED](state, action) {
    return state.merge({
      loading: true,
      success: false,
      options: action.options || {},
    })
  },

  [LOGIN_SUCCEEDED](state, action) {
    const { type, ...params } = action // eslint-disable-line
    return state.merge({
      ...params,
      avatarSelected: !params.showAvatarSelection,
      loading: false,
      success: true,
    })
  },

  [LOGIN_FAILED](state, action) {
    return state.merge({
      loading: false,
      success: false,
      error: action.message,
    })
  },

  [LOGIN_FAILED_RESET](state) {
    return state.merge({
      error: null,
      success: false,
    })
  },

  [LOGIN_UPDATE_AVATAR](state, action) {
    return state.merge({
      avatar: action.avatar,
    })
  },

  [LOGIN_UPDATE_SHOW_AVATAR_SELECTION](state, action) {
    return state.merge({
      showAvatarSelection: action.showAvatarSelection,
    })
  },

  [LOGIN_SET_AVATAR_SELECTED](state, action) {
    return state.merge({
      avatarSelected: action.value,
    })
  },

  [LOGIN_UPDATE_SLOGAN](state, action) {
    return state.merge({
      slogan: action.slogan,
    })
  },

  [LOGIN_UPDATE_NICKNAME](state, action) {
    return state.merge({
      nickname: action.nickname,
    })
  },

  [LOGIN_UPDATE_SOUNDS](state, action) {
    return state.merge({
      sounds: action.sounds,
    })
  },

  [LOGIN_ENABLE_LTI](state) {
    return state.merge({
      lti: true,
    })
  },

})

export default reducer
