// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
// See http://blog.mxstbr.com/2016/01/react-apps-with-pages for more information
// about the code splitting business
import { getHooks } from './utils/hooks.js'

const errorLoading = (err) => {
  console.error('Dynamic page loading failed', err) // eslint-disable-line no-console
}

const loadModule = (cb) => (componentModule) => {
  cb(null, componentModule.default)
}

import { pushRoute, setLoginRedirect } from './containers/App/actions.js'

const requireAdminLogin = (store) => (nextState, replace) => {
  const state = store.getState()
  let loginValid = false
  if (state.has('adminLogin')) {
    const login = state.get('adminLogin')
    if (
      login.has('email')
      && login.has('token')
    ) {
      loginValid = true
    }
  }
  if (!loginValid) {
    replace('/admin/login')
  }
}

const trackRoute = (title) => {
  window.tracker.setCustomUrl(window.location.href)
  window.tracker.trackPageView(title)
}

const hasVailidLogin = (store, client) => {
  const state = store.getState()
  if (state.has('login')) {
    const login = state.get('login')
    if (
      login.has('email')
      && login.has('token')
      && login.get('token')
      && login.has('client')
      && login.get('client') === client
    ) {
      return true
    }
  }

  return false
}

const hasLTILogin = (store) => {
  const state = store.getState()
  if (state.has('login')) {
    const login = state.get('login')
    if (
      login.has('lti') && login.get('lti')
    ) {
      return true
    }
  }

  return false
}

const requireLogin = (store, options) => (nextState, replace) => {
  const opts = {
    ltiAccess: false,
    ...options,
  }
  const { client } = nextState.params
  if (!client) {
    throw new Error('can not route to login page without client parameter')
  }
  if (!hasVailidLogin(store, client)) {
    store.dispatch(setLoginRedirect(nextState.location.pathname))
    replace(`/${client}/login`)
  }

  if (!opts.ltiAccess && hasLTILogin(store)) {
    console.warn('this page is not allowed to access over LTI', nextState.location.pathname) // eslint-disable-line
    replace(`/${client}/lti`)
  }
}

/*
const requireLogout = (store) => (nextState, replace) => {
  const client = nextState.params.client
  if (!client) {
    throw new Error('can not route to login page without client parameter')
  }
  if (hasVailidLogin(store, client)) {
    replace(`/${client}/`)
  }
}
*/

const dispatchRoute = (store, routeObject, nextState) => {
  const route = {
    ...routeObject,
    ...nextState.params,
  }
  if (route.childRoutes) {
    route.childRoutes = route.childRoutes.map((r) => r.name)
  }
  delete route.getComponent
  delete route.indexRoute
  if (typeof route.menuVisible === 'function') {
    route.menuVisible = route.menuVisible()
  }
  store.dispatch(pushRoute(route))
}

import { getSize } from './containers/App/util.js'

const isMobile = () => {
  const size = getSize()
  // Dirty Hack to enabled in App/actions setOS()
  const os = window._os = os || 'desktop' // eslint-disable-line

  if (size.phone && size.portrait) {
    return true
  }

  if (size.phone && os === 'mobile') {
    return true
  }

  return false
}

export default function createRoutes(store) {
  // Create reusable async injectors using getHooks factory
  const { injectReducer, injectSagas } = getHooks(store)

  return [
    {
      path: '/',
      name: 'startPage',
      redirectToLoginOnError: false,
      menuVisible: true,
      receiveNotifications: false,
      getComponent(nextState, cb) {
        trackRoute('StartPage')
        dispatchRoute(store, this, nextState)
        const importModules = Promise.all([
          import('./containers/StartPage/reducer.js'),
          import('./containers/StartPage/sagas.js'),
          import('./containers/StartPage/index.js'),
        ])

        const renderRoute = loadModule(cb)

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('startPage', reducer.default)
          injectSagas(sagas.default)
          renderRoute(component)
        })

        importModules.catch(errorLoading)
      },
    }, {
      path: 'error(/:code)',
      name: 'error',
      redirectToLoginOnError: false,
      menuVisible: false,
      receiveNotifications: false,
      getComponent(nextState, cb) {
        trackRoute('Error')
        dispatchRoute(store, this, nextState)
        import('./containers/ErrorPage/index.js')
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    }, {
      path: '/admin',
      name: 'admin',
      redirectToLoginOnError: false,
      menuVisible: false,
      receiveNotifications: false,
      getComponent(nextState, cb) {
        dispatchRoute(store, this, nextState)
        const importModules = Promise.all([
          import('./containers/AdminContainer/reducer.js'),
          import('./containers/AdminContainer/sagas.js'),
          import('./containers/AdminLoginPage/sagas.js'),
          import('./containers/AdminContainer/index.js'),
          import('./containers/AdminTeamListPage/reducer.js'),
          import('./containers/AdminTeamListPage/sagas.js'),
          import('./containers/AdminAccessGroupListPage/reducer.js'),
          import('./containers/AdminAccessGroupListPage/sagas.js'),
          import('./containers/AdminQuizListPage/reducer.js'),
          import('./containers/AdminQuizListPage/sagas.js'),
          import('./containers/AdminDialog/reducer.js'),
          import('./containers/AdminTaskListPage/reducer.js'),
          import('./containers/AdminTaskListPage/sagas.js'),
          import('./containers/AdminItemListPage/reducer.js'),
          import('./containers/AdminItemListPage/sagas.js'),
        ])

        const renderRoute = loadModule(cb)

        importModules.then(([
          reducer,
          sagas,
          loginSagas,
          component,
          teamReducer,
          teamSagas,
          accessGroupReducer,
          accessGroupSagas,
          quizReducer,
          quizSagas,
          dialogReducer,
          taskListReducer,
          taskListSagas,
          itemListReducer,
          itemListSaga,
        ]) => {
          injectReducer('admin', reducer.default)
          injectReducer('adminTeamListPage', teamReducer.default)
          injectReducer('adminAccessGroupListPage', accessGroupReducer.default)
          injectReducer('adminQuizListPage', quizReducer.default)
          injectReducer('adminDialog', dialogReducer.default)
          injectReducer('adminTaskListPage', taskListReducer.default)
          injectReducer('adminItemListPage', itemListReducer.default)
          injectSagas(sagas.default)
          injectSagas(loginSagas.default)
          injectSagas(teamSagas.default)
          injectSagas(accessGroupSagas.default)
          injectSagas(quizSagas.default)
          injectSagas(taskListSagas.default)
          injectSagas(itemListSaga.default)
          renderRoute(component)
        })

        importModules.catch(errorLoading)
      },
      indexRoute: {
        onEnter: (nextState, replace) => {
          const state = store.getState().toJS()
          if (!state.adminLogin) {
            replace('/admin/login')
          } else if (state.adminLogin.isSuperAdmin) {
            replace('/admin/clients')
          } else if (state.adminLogin.isAdmin) {
            replace('/admin/users')
          } else if (state.adminLogin.isEditor) {
            replace('/admin/quizlist')
          } else if (state.adminLogin.isController) {
            replace('/admin/report')
          } else {
            replace('/admin/login')
          }
        },
      },
      childRoutes: [
        {
          path: 'login(/:token)',
          name: 'adminLogin',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Login')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminLoginPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([component]) => {
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
        }, {
          path: 'system',
          name: 'superAdminSystemInfo',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('SuperAdmin - System Info')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminSuperSystemInfoPage/reducer.js'),
              import('./containers/AdminSuperSystemInfoPage/sagas.js'),
              import('./containers/AdminSuperSystemInfoPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminSuperSystemInfoPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'backups',
          name: 'superAdminBackups',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('SuperAdmin - Backups')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminSuperBackupsPage/reducer.js'),
              import('./containers/AdminSuperBackupsPage/sagas.js'),
              import('./containers/AdminSuperBackupsPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminSuperBackupsPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'contracts',
          name: 'superAdminContracts',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('SuperAdmin - Contracts')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminSuperContractListPage/reducer.js'),
              import('./containers/AdminSuperContractListPage/sagas.js'),
              import('./containers/AdminSuperContractListPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminSuperContractListPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'super/translation',
          name: 'superAdminTranslationImport',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('SuperAdmin - Translation')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminSuperTranslationImportPage/reducer.js'),
              import('./containers/AdminSuperTranslationImportPage/sagas.js'),
              import('./containers/AdminSuperTranslationImportPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminSuperTranslationImportPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'super/contracts/:id',
          name: 'superAdminContract',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('SuperAdmin - Contract')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminSuperContractPage/reducer.js'),
              import('./containers/AdminSuperContractPage/sagas.js'),
              import('./containers/AdminSuperContractPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminSuperContractPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'super/contract-create',
          name: 'superAdminContractCreate',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('SuperAdmin - Contract create')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminSuperContractCreatePage/reducer.js'),
              import('./containers/AdminSuperContractCreatePage/sagas.js'),
              import('./containers/AdminSuperContractCreatePage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminSuperContractCreatePage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'super/avatars',
          name: 'superAdminAvatarPage',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('SuperAdmin - Avatars')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminSuperAvatarPage/reducer.js'),
              import('./containers/AdminSuperAvatarPage/sagas.js'),
              import('./containers/AdminSuperAvatarPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminSuperAvatarPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'clients',
          name: 'superAdminClients',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('SuperAdmin - Clients')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminSuperClientsPage/reducer.js'),
              import('./containers/AdminSuperClientsPage/sagas.js'),
              import('./containers/AdminSuperClientsPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminSuperClientsPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'super/client/:id',
          name: 'superAdminClient',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('SuperAdmin - Client')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminSuperClientPage/reducer.js'),
              import('./containers/AdminSuperClientPage/sagas.js'),
              import('./containers/AdminSuperClientPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminSuperClientPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'super/client-create',
          name: 'superAdminClientCreate',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('SuperAdmin - Client create')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminSuperClientCreatePage/reducer.js'),
              import('./containers/AdminSuperClientCreatePage/sagas.js'),
              import('./containers/AdminSuperClientCreatePage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminSuperClientCreatePage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'super/add-metabase/:client',
          name: 'superAdminClientCreate',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('SuperAdmin - Add Metabase')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminSuperMetabaseIntegrationPage/reducer.js'),
              import('./containers/AdminSuperMetabaseIntegrationPage/sagas.js'),
              import('./containers/AdminSuperMetabaseIntegrationPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminSuperMetabaseIntegrationPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'settings',
          name: 'adminClientSettings',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Client Settings')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminClientSettings/reducer.js'),
              import('./containers/AdminClientSettings/sagas.js'),
              import('./containers/AdminClientSettings/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminClientSettings', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'users',
          name: 'adminUsersSettings',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Users')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminUsersPage/reducer.js'),
              import('./containers/AdminUsersPage/sagas.js'),
              import('./containers/AdminUsersPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminUsersPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'user/:id',
          name: 'adminUserSettings',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - User')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminUserPage/reducer.js'),
              import('./containers/AdminUserPage/sagas.js'),
              import('./containers/AdminUserPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminUserPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'user-create',
          name: 'adminUserCreate',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - User create')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminUserCreatePage/reducer.js'),
              import('./containers/AdminUserCreatePage/sagas.js'),
              import('./containers/AdminUserCreatePage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminUserCreatePage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'user-import',
          name: 'adminUserImport',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - User - Import')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminUserImportPage/reducer.js'),
              import('./containers/AdminUserImportPage/sagas.js'),
              import('./containers/AdminUserImportPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminUserImportPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'tasks',
          name: 'adminTaskList',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Tasks')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              // import('./containers/AdminTaskListPage/reducer.js'),
              // import('./containers/AdminTaskListPage/sagas.js'),
              import('./containers/AdminTaskListPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([component]) => {
              // injectReducer('adminTaskListPage', reducer.default)
              // injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'skills',
          name: 'adminSkillList',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Skills')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminSkillListPage/reducer.js'),
              import('./containers/AdminSkillListPage/sagas.js'),
              import('./containers/AdminSkillListPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminSkillListPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'skills/:id',
          name: 'adminSkill',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Skill')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminSkillPage/reducer.js'),
              import('./containers/AdminSkillPage/sagas.js'),
              import('./containers/AdminSkillPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminSkillPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'skill-create',
          name: 'adminSkillCreate',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Skill create')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminSkillCreatePage/reducer.js'),
              import('./containers/AdminSkillCreatePage/sagas.js'),
              import('./containers/AdminSkillCreatePage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminSkillCreatePage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'chapters',
          name: 'adminChapterList',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Chapters')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminChapterListPage/reducer.js'),
              import('./containers/AdminChapterListPage/sagas.js'),
              import('./containers/AdminChapterListPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminChapterListPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'chapters/:id',
          name: 'adminChapter',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Chapter')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminChapterPage/reducer.js'),
              import('./containers/AdminChapterPage/sagas.js'),
              import('./containers/AdminChapterPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminChapterPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'chapter-create',
          name: 'adminChapterCreate',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Chapter create')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminChapterCreatePage/reducer.js'),
              import('./containers/AdminChapterCreatePage/sagas.js'),
              import('./containers/AdminChapterCreatePage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminChapterCreatePage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'items',
          name: 'adminItemList',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Items')
            dispatchRoute(store, this, nextState)
            const importModules = import('./containers/AdminItemListPage/index.js')

            const renderRoute = loadModule(cb)

            importModules.then((component) => {
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'items/:id',
          name: 'adminItem',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Item')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminItemPage/reducer.js'),
              import('./containers/AdminItemPage/sagas.js'),
              import('./containers/AdminItemPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminItemPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'item-create',
          name: 'adminItemCreate',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Item create')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminItemCreatePage/reducer.js'),
              import('./containers/AdminItemCreatePage/sagas.js'),
              import('./containers/AdminItemCreatePage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminItemCreatePage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'quests',
          name: 'adminQuestList',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Quests')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminQuestListPage/reducer.js'),
              import('./containers/AdminQuestListPage/sagas.js'),
              import('./containers/AdminQuestListPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminQuestListPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'quests/:id',
          name: 'adminQuest',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Quest')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminQuestPage/reducer.js'),
              import('./containers/AdminQuestPage/sagas.js'),
              import('./containers/AdminQuestPage/index.js'),
            ])

            const renderRoute = loadModule(cb)
            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminQuestPage', reducer.default)
              injectSagas(sagas.default)

              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'quest-create',
          name: 'adminQuestCreate',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Quest create')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminQuestCreatePage/reducer.js'),
              import('./containers/AdminQuestCreatePage/sagas.js'),
              import('./containers/AdminQuestCreatePage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminQuestCreatePage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'quizlist',
          name: 'adminQuizList',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Quizlist')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              // import('./containers/AdminQuizListPage/reducer.js'),
              // import('./containers/AdminQuizListPage/sagas.js'),
              import('./containers/AdminQuizListPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([component]) => {
              // injectReducer('adminQuizListPage', reducer.default)
              // injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'quizlist/:id',
          name: 'adminQuiz',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Quiz')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminQuizPage/reducer.js'),
              import('./containers/AdminQuizPage/sagas.js'),
              import('./containers/AdminQuizPage/index.js'),
              import('./containers/AdminQuizUpdate/reducer.js'),
              import('./containers/AdminQuizUpdate/sagas.js'),
              import('./containers/AdminQuestionListPage/reducer.js'),
              import('./containers/AdminQuestionListPage/sagas.js'),
              import('./containers/AdminPlayerExportListPage/reducer.js'),
              import('./containers/AdminPlayerExportListPage/sagas.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component, quizUpdateReducer, quizUpdateSaga, questionReducer, questionSaga, playerExportReducer, playerExportSaga]) => {
              injectReducer('adminQuizPage', reducer.default)
              injectReducer('adminQuizUpdate', quizUpdateReducer.default)
              injectSagas(quizUpdateSaga.default)
              injectSagas(sagas.default)
              injectReducer('adminQuestionListPage', questionReducer.default)
              injectSagas(questionSaga.default)
              injectReducer('adminPlayerExportListPage', playerExportReducer.default)
              injectSagas(playerExportSaga.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'quizlist-create',
          name: 'adminQuizCreate',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Quiz create')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminQuizCreatePage/reducer.js'),
              import('./containers/AdminQuizCreatePage/sagas.js'),
              import('./containers/AdminQuizCreatePage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminQuizCreatePage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'quiztranslation/:id/:lang',
          name: 'adminQuizTranslation',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Quiz Translation')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminQuizTranslationPage/reducer.js'),
              import('./containers/AdminQuizTranslationPage/sagas.js'),
              import('./containers/AdminQuizTranslationPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminQuizTranslationPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'quiz-import',
          name: 'adminQuizImport',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Quiz - Import')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminQuizImportPage/reducer.js'),
              import('./containers/AdminQuizImportPage/sagas.js'),
              import('./containers/AdminQuizImportPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminQuizImportPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'teamlist',
          name: 'adminTeamList',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Teamlist')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              // import('./containers/AdminTeamListPage/reducer.js'),
              // import('./containers/AdminTeamListPage/sagas.js'),
              import('./containers/AdminTeamListPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([component]) => {
              // injectReducer('adminTeamListPage', reducer.default)
              // injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'teamlist/:id',
          name: 'adminTeamSettings',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Team')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminTeamPage/reducer.js'),
              import('./containers/AdminTeamPage/sagas.js'),
              import('./containers/AdminTeamPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminTeamPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'accessgroups',
          name: 'adminAccessGroupList',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - AccessGrouplist')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              // import('./containers/AdminTeamListPage/reducer.js'),
              // import('./containers/AdminTeamListPage/sagas.js'),
              import('./containers/AdminAccessGroupListPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([component]) => {
              // injectReducer('adminTeamListPage', reducer.default)
              // injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'group-create',
          name: 'adminAccessGroupCreate',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Access group create')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminAccessGroupCreatePage/reducer.js'),
              import('./containers/AdminAccessGroupCreatePage/sagas.js'),
              import('./containers/AdminAccessGroupCreatePage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminAccessGroupCreatePage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'accessgroups/:id',
          name: 'adminAccessGroupSettings',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Access Group')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminAccessGroupPage/reducer.js'),
              import('./containers/AdminAccessGroupPage/sagas.js'),
              import('./containers/AdminAccessGroupPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminAccessGroupPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'report',
          name: 'adminReport',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Report')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminMetabasePage/reducer.js'),
              import('./containers/AdminMetabasePage/sagas.js'),
              import('./containers/AdminMetabasePage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminMetabasePage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        }, {
          path: 'leaderboard',
          name: 'adminLeaderboard',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Admin - Leaderboard')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AdminLeaderboardPage/reducer.js'),
              import('./containers/AdminLeaderboardPage/sagas.js'),
              import('./containers/AdminLeaderboardPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('adminLeaderboardPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireAdminLogin(store),
        },
      ],
    }, {
      path: '/controlling',
      name: 'Controlling',
      redirectToLoginOnError: false,
      menuVisible: true,
      receiveNotifications: false,
      getComponent(nextState, cb) {
        trackRoute('Controlling')
        dispatchRoute(store, this, nextState)
        const importModules = Promise.all([
          import('./containers/Controlling/reducer.js'),
          import('./containers/Controlling/sagas.js'),
          import('./containers/Controlling/index.js'),
        ])

        const renderRoute = loadModule(cb)

        importModules.then(([reducer, sagas, component]) => {
          injectReducer('controlling', reducer.default)
          injectSagas(sagas.default)
          renderRoute(component)
        })

        importModules.catch(errorLoading)
      },
    }, {
      path: '/:client',
      name: 'client',
      redirectToLoginOnError: false,
      menuVisible: true,
      receiveNotifications: false,
      getComponent(nextState, cb) {
        dispatchRoute(store, this, nextState)
        const importModules = Promise.all([
          import('./containers/ClientContainer/sagas.js'),
          import('./containers/Quiz/sagas.js'),
          import('./containers/Foreplay/sagas.js'),
          import('./containers/Toasty/sagas.js'),
          import('./containers/Sound/sagas.js'),
          import('./containers/QuizCards/sagas.js'),
          import('./containers/QuizCards/reducer.js'),
          import('./containers/ClientContainer/index.js'),
        ])

        const renderRoute = loadModule(cb)

        importModules.then(([clientSagas, quizSaga, foreplaySaga, toastySaga, soundSaga, quizCardsSaga, quizCardsReducer, component]) => {
          // loaded for rehydration as root reducer
          // injectReducer('client', reducer.default)
          // quiz saga for start quiz
          injectSagas(clientSagas.default)
          injectSagas(quizSaga.default)
          injectSagas(foreplaySaga.default)
          injectSagas(toastySaga.default)
          injectSagas(soundSaga.default)
          injectSagas(quizCardsSaga.default)
          injectReducer('quizCards', quizCardsReducer.default)
          renderRoute(component)
        })

        importModules.catch(errorLoading)
      },
      indexRoute: {
        onEnter: (nextState, replace) => (isMobile()
          ? replace(`/${nextState.params.client}/start`)
          : replace(`/${nextState.params.client}/quizlist`)),
      },
      childRoutes: [
        {
          path: 'dashboard',
          name: 'dashboard',
          redirectToLoginOnError: true,
          menuVisible: true,
          receiveNotifications: true,
          returnPage: true,
          getComponent(nextState, cb) {
            trackRoute('Dashboard')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/Dashboard/reducer.js'),
              import('./containers/Dashboard/sagas.js'),
              import('./containers/Dashboard/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('dashboard', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireLogin(store),
        }, {
          path: 'quests',
          name: 'quests',
          redirectToLoginOnError: true,
          menuVisible: true,
          receiveNotifications: true,
          returnPage: true,
          getComponent(nextState, cb) {
            trackRoute('Quests')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/Quests/reducer.js'),
              import('./containers/Quests/sagas.js'),
              import('./containers/Quests/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('quests', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireLogin(store),
        }, {
          path: 'register',
          name: 'register',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Register')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/RegisterPage/reducer.js'),
              import('./containers/RegisterPage/sagas.js'),
              import('./containers/RegisterPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('registerPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
        }, {
          path: 'login(/:token)(/:quizId)(/:gameMode)',
          name: 'loginPage',
          redirectToLoginOnError: false,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Login')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              // import('./containers/LoginPage/reducer.js'),
              import('./containers/LoginPage/sagas.js'),
              import('./containers/LoginPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([/* reducer, */sagas, component]) => {
              // loaded for rehydration as root reducer
              // injectReducer('login', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          // onEnter: requireLogout(store),
        }, {
          path: 'contract/:id',
          name: 'contractPage',
          redirectToLoginOnError: false,
          menuVisible: false,
          receiveNotifications: true,
          getComponent(nextState, cb) {
            trackRoute('Contract')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/ContractPage/reducer.js'),
              import('./containers/ContractPage/sagas.js'),
              import('./containers/ContractPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('contractPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
        }, {
          path: 'reset',
          name: 'resetPasswordPage',
          redirectToLoginOnError: false,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Reset Password')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/ResetPasswordPage/reducer.js'),
              import('./containers/ResetPasswordPage/sagas.js'),
              import('./containers/ResetPasswordPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('resetPasswordPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
        }, {
          path: 'confirmation/:token',
          name: 'confirmationPage',
          redirectToLoginOnError: false,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Password Confirmation')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/ConfirmUserPage/reducer.js'),
              import('./containers/ConfirmUserPage/sagas.js'),
              import('./containers/ConfirmUserPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('confirmUserPage', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
        }, {
          path: 'start/:type/:q(/:u)',
          name: 'foreplayPage',
          redirectToLoginOnError: true,
          menuVisible: () => isMobile(),
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Foreplay')
            dispatchRoute(store, this, nextState)
            const renderRoute = loadModule(cb)
            import('./containers/Foreplay/index.js').then((component) => {
              renderRoute(component)
            }).catch(errorLoading)
          },
          onEnter: requireLogin(store, { ltiAccess: true }),
        }, {
          path: 'search/:quizId(/:priceId)',
          name: 'foreplayPlayerSearch',
          redirectToLoginOnError: true,
          menuVisible: () => isMobile(),
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('Foreplay Player Search')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/ForeplayPlayerSearch/reducer.js'),
              import('./containers/ForeplayPlayerSearch/sagas.js'),
              import('./containers/ForeplayPlayerSearch/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('foreplayPlayerSearch', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireLogin(store, { ltiAccess: true }),
        }, {
          path: 'quiz/:quizInstanceId',
          name: 'quizPage',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute(`Quiz ${nextState.params.quizInstanceId}`)
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              // import('./containers/Quiz/reducer.js'),
              // import('./containers/Quiz/sagas.js'), <-- loaded on client
              import('./containers/Quiz/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([/* reducer, sagas, */component]) => {
              // loaded for rehydration as root reducer
              // injectReducer('quiz', reducer.default)
              // injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireLogin(store, { ltiAccess: true }),
        }, {
          path: 'quizlist',
          name: 'quizlist',
          redirectToLoginOnError: true,
          menuVisible: true,
          receiveNotifications: true,
          returnPage: true,
          getComponent(nextState, cb) {
            trackRoute('Quizlist')
            dispatchRoute(store, this, nextState)
            const importModules = import('./containers/QuizCards/index.js')

            const renderRoute = loadModule(cb)

            importModules.then((component) => {
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireLogin(store),
        }, {
          path: 'quizlist/:id',
          name: 'quizdetails',
          redirectToLoginOnError: true,
          menuVisible: true,
          receiveNotifications: true,
          returnPage: true,
          getComponent(nextState, cb) {
            trackRoute(`QuizDetails ${nextState.params.id}`)
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/QuizDetailPage/reducer.js'),
              import('./containers/QuizDetailPage/sagas.js'),
              import('./containers/QuizDetailPage/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('quizDetail', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireLogin(store),
        }, {
          path: 'avatar',
          name: 'avatarSelection',
          redirectToLoginOnError: true,
          menuVisible: true,
          receiveNotifications: true,
          getComponent(nextState, cb) {
            trackRoute('Avatar Selection')
            dispatchRoute(store, this, nextState)
            const importModules = Promise.all([
              import('./containers/AvatarSelection/reducer.js'),
              import('./containers/AvatarSelection/sagas.js'),
              import('./containers/AvatarSelection/index.js'),
            ])

            const renderRoute = loadModule(cb)

            importModules.then(([reducer, sagas, component]) => {
              injectReducer('avatarSelection', reducer.default)
              injectSagas(sagas.default)
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireLogin(store),
        }, {
          path: 'page/:id',
          name: 'page',
          redirectToLoginOnError: true,
          menuVisible: true,
          receiveNotifications: true,
          getComponent(nextState, cb) {
            trackRoute('CMS Page')
            dispatchRoute(store, this, nextState)
            const importModules = import('./containers/Page/index.js')
            const renderRoute = loadModule(cb)

            importModules.then((component) => {
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireLogin(store),
        }, {
          path: 'options',
          name: 'options',
          redirectToLoginOnError: true,
          menuVisible: true,
          receiveNotifications: true,
          getComponent(nextState, cb) {
            trackRoute('Options Menu')
            dispatchRoute(store, this, nextState)
            const importModules = import('./containers/MenuPage/index.js')
            const renderRoute = loadModule(cb)

            importModules.then((component) => {
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
          onEnter: requireLogin(store),
        }, {
          path: 'profile',
          name: 'profile',
          redirectToLoginOnError: true,
          menuVisible: true,
          receiveNotifications: true,
          getComponent(nextState, cb) {
            trackRoute('Profile')
            dispatchRoute(store, this, nextState)
            const importModules = import('./containers/Profile/index.js')

            const renderRoute = loadModule(cb)

            importModules.then((component) => {
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
        }, {
          path: 'settings',
          name: 'settings',
          redirectToLoginOnError: true,
          menuVisible: true,
          receiveNotifications: true,
          getComponent(nextState, cb) {
            trackRoute('Settings')
            dispatchRoute(store, this, nextState)
            const importModules = import('./containers/Settings/index.js')
            const renderRoute = loadModule(cb)

            importModules.then((component) => {
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
        }, {
          path: 'terms',
          name: 'terms',
          redirectToLoginOnError: true,
          menuVisible: true,
          receiveNotifications: true,
          getComponent(nextState, cb) {
            trackRoute('Terms and Conditions')
            dispatchRoute(store, this, nextState)
            const importModules = import('./containers/Terms/index.js')
            const renderRoute = loadModule(cb)

            importModules.then((component) => {
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
        }, {
          path: 'change',
          name: 'change',
          redirectToLoginOnError: true,
          menuVisible: true,
          receiveNotifications: true,
          getComponent(nextState, cb) {
            trackRoute('Change Password')
            dispatchRoute(store, this, nextState)
            const importModules = import('./containers/ChangePassword/index.js')
            const renderRoute = loadModule(cb)

            importModules.then((component) => {
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
        }, {
          path: 'delete',
          name: 'delete',
          redirectToLoginOnError: true,
          menuVisible: true,
          receiveNotifications: true,
          getComponent(nextState, cb) {
            trackRoute('Delete Account')
            dispatchRoute(store, this, nextState)
            const importModules = import('./containers/DeleteAccount/index.js')
            const renderRoute = loadModule(cb)

            importModules.then((component) => {
              renderRoute(component)
            })

            importModules.catch(errorLoading)
          },
        }, {
          path: 'start',
          name: 'start',
          redirectToLoginOnError: true,
          menuVisible: true,
          receiveNotifications: true,
          returnPage: true,
          getComponent(nextState, cb) {
            trackRoute('Start')
            dispatchRoute(store, this, nextState)
            import('./containers/StartMobile/index.js')
              .then(loadModule(cb))
              .catch(errorLoading)
          },
          onEnter: requireLogin(store),
        }, {
          path: 'tos',
          name: 'tos',
          getComponent(nextState, cb) {
            trackRoute('TOS')
            dispatchRoute(store, this, nextState)
            import('./containers/TosPage/index.js')
              .then(loadModule(cb))
              .catch(errorLoading)
          },
        }, {
          path: 'imprint',
          name: 'imprint',
          getComponent(nextState, cb) {
            trackRoute('Imprint')
            dispatchRoute(store, this, nextState)
            import('./containers/ImprintPage/index.js')
              .then(loadModule(cb))
              .catch(errorLoading)
          },
        }, {
          path: 'contact',
          name: 'contact',
          getComponent(nextState, cb) {
            trackRoute('Contact')
            dispatchRoute(store, this, nextState)
            import('./containers/ContactPage/index.js')
              .then(loadModule(cb))
              .catch(errorLoading)
          },
        }, {
          path: 'lti',
          name: 'ltiFinished',
          redirectToLoginOnError: true,
          menuVisible: false,
          receiveNotifications: false,
          getComponent(nextState, cb) {
            trackRoute('LTI Finished')
            dispatchRoute(store, this, nextState)
            import('./containers/LTIFinishedPage/index.js')
              .then(loadModule(cb))
              .catch(errorLoading)
          },
          onEnter: requireLogin(store, { ltiAccess: true }),
        },
      ],
    }, {
      path: '*',
      name: 'notfound',
      redirectToLoginOnError: true,
      menuVisible: true,
      receiveNotifications: false,
      getComponent(nextState, cb) {
        trackRoute('Not Found')
        dispatchRoute(store, this, nextState)
        import('./containers/NotFoundPage/index.js')
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
  ]
}
