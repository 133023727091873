/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux-immutable'
import { fromJS } from 'immutable'
import { LOCATION_CHANGE } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form/immutable'

// for rehydration
import appReducer from './containers/App/reducer.js'
import clientReducer from './containers/ClientContainer/reducer.js'
import loginReducer from './containers/LoginPage/reducer.js'
import headerReducer from './containers/Header/reducer.js'
import quizReducer from './containers/Quiz/reducer.js'
import foreplayReducer from './containers/Foreplay/reducer.js'
import toastyReducer from './containers/Toasty/reducer.js'
import adminLoginPageReducer from './containers/AdminLoginPage/reducer.js'
import soundReducer from './containers/Sound/reducer.js'
/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  locationBeforeTransitions: null,
})

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        locationBeforeTransitions: action.payload,
      })
    default:
      return state
  }
}

/*
const immutableize = (reducer) => (state, action) =>
  fromJS(reducer(state ? state.toJS() : state, action));
*/

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
  return combineReducers({
    app: appReducer,
    route: routeReducer,
    form: formReducer,
    client: clientReducer,
    login: loginReducer,
    header: headerReducer,
    quiz: quizReducer,
    foreplay: foreplayReducer,
    toasty: toastyReducer,
    adminLogin: adminLoginPageReducer,
    sound: soundReducer,
    language: (state) => state, // initial state is set during app loading
    ...asyncReducers,
  })
}
