/*
 *
 * LanguageProvider
 *
 */

import React from 'react'
import { PropTypes } from 'prop-types'
import { IntlProvider } from 'react-intl'

export class LanguageProvider extends React.Component { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    children: PropTypes.node,
    language: PropTypes.string.isRequired,
    messages: PropTypes.object.isRequired,
  }

  render() {
    return (
      <IntlProvider locale={this.props.language} messages={this.props.messages}>
        {this.props.children}
      </IntlProvider>
    )
  }
}

export default LanguageProvider
