import { PropTypes } from 'prop-types'

// export const colorsShape = PropTypes.shape({})
export const colorsShape = PropTypes.object

export const clientShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  colors: colorsShape,
})

export default clientShape
