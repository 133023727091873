/*
 *
 * App reducer
 *
 */

import { fromJS } from 'immutable'
import createReducer from '../../createReducer.js'
import { formatPattern } from 'react-router'
import { getSize } from './util.js'

import {
  SET_DIMENSIONS,
  ROUTE,
  SET_LOGIN_REDIRECT,
  SET_ROUTE_TRANSITION,
  SET_FEATURE,
  SET_ONLINE_USERS,
  SET_RESIZING,
  SET_CONTRACTS,
  READ_CONTRACT,
  READ_CONTRACT_SUCCESS,
  RESET,
  SET_LIVE_RESIZE,

  PROFILE_FETCH_REQUESTED,
  PROFILE_FETCH_SUCCEEDED,
  PROFILE_FETCH_FAILED,
  PROFILE_TOGGLE_SEND_NOTIFICATIONS,
  PROFILE_TOGGLE_SEND_NEWS,
  PROFILE_TOGGLE_FIND_ME_BY_EMAIL,
  PROFILE_TOGGLE_SOUND,
  PROFILE_SET_SLOGAN,
  PROFILE_SET_NICKNAME,
  PROFILE_SAVED,
  PROFILE_DELETE_REQUESTED,
  PROFILE_DELETE_SUCCEEDED,
  PROFILE_DELETE_FAILED,
  PROFILE_TOGGLE_SAVEDELETE,
  PROFILE_CHANGE_LANGUAGE,
  PROFILE_CHANGE_SERVER_LANGUAGE,
  PROFILE_SET_FIRSTNAME,
  PROFILE_SET_LASTNAME,
  PROFILE_SET_GENDER,
  PROFILE_SET_COUNTRY,

  INVENTORY_FETCH_SUCCEEDED,
  INVENTORY_FETCH_FAILED,
  INVENTORY_FETCH_REQUESTED,

  INVENTORY_OPEN,
  INVENTORY_CLOSE,
} from './constants.js'

const initialState = fromJS({
  resizing: false,
  liveResize: false, // render during resize
  os: 'desktop',
  size: getSize({
    phone: false,
    tablet: false,
    desktop: true,
    portrait: false,
    landscape: true,
    scale: 62.5,
    tight: false,
    scrollBarWidth: 0,
  }),
  route: {
    name: 'init',
  },
  loginRedirect: null,
  returnPath: '/',
  updating: false,
  transition: 'forward', // backward
  features: {
    slideTransitions: false,
    fadeTransitions: false,
    quizCardsAnimation: false,
  },
  onlineUsers: [],
  contracts: [],
  contractsLoading: false,
  contractsUpdateTime: 0,

  profileSaveDelete: false,
  profileError: null,
  profileSaved: null,
  profile: {},
  profileLoaded: false,
  languages: [],

  inventoryLoaded: false,
  inventory: [],
  inventoryOpen: false,
  inventorySelectedItem: null,
  inventoryPage: null,
  inventoryError: null,
})

export const reducer = createReducer(initialState, {

  [SET_LIVE_RESIZE](state, action) {
    return state.merge({
      liveResize: action.value,
    })
  },

  [RESET](state) {
    return state.merge({
      contracts: [],
      contractsUpdateTime: 0,
      contractsLoading: false,
      onlineUsers: [],
      returnPath: '/',
      loginRedirect: null,
    })
  },

  [SET_CONTRACTS](state, action) {
    return state.merge({
      contracts: action.contracts,
      contractsUpdateTime: Math.round((new Date()).getTime() / 1000),
    })
  },

  [READ_CONTRACT](state) {
    return state.merge({
      contractsLoading: true,
    })
  },

  [READ_CONTRACT_SUCCESS](state, action) {
    const contracts = state.get('contracts').map(
      (uc) => {
        if (uc.get('contract').get('id') === action.contract.id) {
          return uc.merge({
            accepted: action.accepted,
            readTime: (new Date()).toISOString(),
          })
        }
        return uc
      }
    )

    return state.merge({
      contracts,
      contractsLoading: false,
    })
  },

  [SET_RESIZING](state, action) {
    return state.merge({
      resizing: action.resizing,
    })
  },

  [SET_FEATURE](state, action) {
    return state.merge({
      features: state.get('features').merge({
        [action.key]: action.value,
      }),
    })
  },

  [SET_DIMENSIONS](state, action) {
    return state.merge({
      size: getSize(state.get('size').toJS(), action.dimensions),
      resizing: false,
    })
  },

  [SET_ROUTE_TRANSITION](state, action) {
    return state.merge({
      transition: action.name,
    })
  },

  [ROUTE](state, action) {
    const prevRoute = state.get('route')
    const newRoute = action.route
    if (prevRoute.has('childRoutes') && prevRoute.get('childRoutes').includes(newRoute.name)) {
      newRoute.parent = prevRoute
    }
    let returnPath = state.get('returnPath')
    if (newRoute.returnPage && !newRoute.childRoutes) {
      // TODO: handle sub sub paths
      returnPath = `/${newRoute.client}/${formatPattern(newRoute.path, newRoute)}`
    } else if (returnPath === '/' && newRoute.client) { // init the returnPath
      returnPath = `/${newRoute.client}`
    }
    return state.merge({
      route: newRoute,
      returnPath,
    })
  },

  [SET_LOGIN_REDIRECT](state, action) {
    return state.merge({
      loginRedirect: action.pathname,
    })
  },

  [SET_ONLINE_USERS](state, action) {
    return state.merge({
      onlineUsers: action.users,
    })
  },

  // profile
  [PROFILE_TOGGLE_SAVEDELETE](state) {
    return state.merge({
      profileSaveDelete: !state.get('profileSaveDelete'),
    })
  },

  [PROFILE_DELETE_REQUESTED](state) {
    return state.merge({
      profileError: null,
    })
  },

  [PROFILE_DELETE_SUCCEEDED]() {
    return initialState
  },

  [PROFILE_DELETE_FAILED](state, action) {
    return state.merge({
      profileError: action.message,
    })
  },

  [PROFILE_SAVED](state) {
    return state.merge({
      profileSaved: (new Date()).toLocaleTimeString(),
      unsavedLanguage: null,
    })
  },

  [PROFILE_SET_SLOGAN](state, action) {
    return state.merge({
      profile: state.get('profile').merge({
        slogan: action.value,
      }),
    })
  },

  [PROFILE_SET_FIRSTNAME](state, action) {
    return state.merge({
      profile: state.get('profile').merge({
        firstname: action.value,
      }),
    })
  },

  [PROFILE_SET_LASTNAME](state, action) {
    return state.merge({
      profile: state.get('profile').merge({
        lastname: action.value,
      }),
    })
  },

  [PROFILE_SET_COUNTRY](state, action) {
    return state.merge({
      profile: state.get('profile').merge({
        country: action.value,
      }),
    })
  },

  [PROFILE_SET_GENDER](state, action) {
    return state.merge({
      profile: state.get('profile').merge({
        gender: action.value,
      }),
    })
  },

  [PROFILE_CHANGE_LANGUAGE](state, action) {
    return state.merge({
      profile: state.get('profile').merge({
        unsavedLanguage: action.id,
      }),
    })
  },

  [PROFILE_CHANGE_SERVER_LANGUAGE](state, action) {
    return state.merge({
      profile: state.get('profile').merge({
        language: action.id,
      }),
    })
  },

  [PROFILE_SET_NICKNAME](state, action) {
    return state.merge({
      profile: state.get('profile').merge({
        nickname: action.value,
      }),
    })
  },

  [PROFILE_TOGGLE_SEND_NOTIFICATIONS](state) {
    return state.merge({
      profile: state.get('profile').merge({
        sendNotifications: !state.get('profile').get('sendNotifications'),
      }),
    })
  },

  [PROFILE_TOGGLE_SEND_NEWS](state) {
    return state.merge({
      profile: state.get('profile').merge({
        sendNews: !state.get('profile').get('sendNews'),
      }),
    })
  },

  [PROFILE_TOGGLE_FIND_ME_BY_EMAIL](state) {
    return state.merge({
      profile: state.get('profile').merge({
        findMeByEmail: !state.get('profile').get('findMeByEmail'),
      }),
    })
  },

  [PROFILE_TOGGLE_SOUND](state) {
    return state.merge({
      profile: state.get('profile').merge({
        sounds: !state.get('profile').get('sounds'),
      }),
    })
  },

  [PROFILE_FETCH_REQUESTED](state) {
    return state.merge({
      profileError: null,
      profileSaved: null,
    })
  },

  [PROFILE_FETCH_SUCCEEDED](state, action) {
    return state.merge({
      profile: action.profile,
      languages: action.languages || state.get('languages'),
      profileLoaded: true,
    })
  },

  [PROFILE_FETCH_FAILED](state, action) {
    return state.merge({
      profileLoaded: false,
      profileError: action.message,
    })
  },

  [INVENTORY_FETCH_REQUESTED](state) {
    return state.merge({
      inventoryError: null,
    })
  },

  [INVENTORY_FETCH_SUCCEEDED](state, action) {
    return state.merge({
      inventory: action.inventory,
      inventoryLoaded: true,
      inventoryError: null,
    })
  },

  [INVENTORY_FETCH_FAILED](state, action) {
    return state.merge({
      inventoryLoaded: false,
      inventoryError: action.message,
    })
  },

  [INVENTORY_OPEN](state, action) {
    return state.merge({
      inventoryOpen: true,
      inventorySelectedItem: action.item,
      inventoryPage: action.page,
    })
  },

  [INVENTORY_CLOSE](state) {
    return state.merge({
      inventoryOpen: false,
    })
  },
})

export default reducer
