/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
// import 'babel-polyfill'
import 'intl'

// TODO constrain eslint import/no-unresolved rule to this block

import 'typeface-roboto-condensed' // eslint-disable-line
import 'sanitize.css'
// Init localforage with a dummy fallback
import { dummyDriver } from './dummy-driver.js'
import localforage from 'localforage'
localforage.defineDriver(dummyDriver)
localforage.config({
  driver: [
    localforage.LOCALSTORAGE,
    localforage.INDEXEDDB,
    localforage.WEBSQL,
    dummyDriver._driver, // eslint-disable-line
  ],
})

// Import all the third party stuff
import Tracker from './tracker.js'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router, browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import configureStore from './store.js'
import { ensureSingleInstance } from './single.js'
import { LanguageProvider } from './containers/LanguageProvider/index.js'
import { getTopInset } from './containers/App/util.js'
import { addPluginOption } from './containers/ClientContainer/plugins.js'
import supportedLanguageIds from './language-ids.js'

ensureSingleInstance(() => {
  window.location.href = `http://bizgames.at/bizquiz-session.html?return=${encodeURIComponent(window.location.href)}`
})

// setup the tracker
let trackerUrl = 'https://bizquiz.cloud/events'
if (window.location.host.indexOf('bizquiz.cloud') >= 0) {
  // we are at home
  trackerUrl = `${window.location.protocol}//${window.location.host}/events`
} else if (window.location.host.indexOf('localhost') >= 0) {
  // use the dev server
  trackerUrl = 'https://foo.bizquiz.cloud/events'
}

const tracker = new Tracker(`${trackerUrl}/add.php`) // eslint-disable-line
// track the version
tracker.trackPageView(`BizQuiz reload ${VERSION}`) // eslint-disable-line
window.tracker = tracker

/* eslint-disable */
console.log(`
  ______ _     _____       _
  | ___ (_)   |  _  |     (_)
  | |_/ /_ ___| | | |_   _ _ ____
  | ___ \\ |_  / | | | | | | |_  /
  | |_/ / |/ /\\ \\/' / |_| | |/ /
  \\____/|_/___|\\_/\\_\\\\__,_|_/___|

Version: ${VERSION}
Commit: ${COMMITHASH}
Build time: ${BUILD_TIME}
Features:
\tcordova: ${window.cordova ? window.cordova.version : 'off'}
\ttopInset: ${getTopInset()}px`)

/* eslint-enable */

// for token
import { getApi } from './api.js'
const Api = getApi()

// import the contract translation css helper
import './contract-translation.css'

// Import the global CSS file folder
import './styles.css'

// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
import { selectLocationState } from './containers/App/selectors.js'

// Set up the router, wrapping all Routes in the App component
import App from './containers/App/index.js'
import createRoutes from './routes.js'

const rootDomElement = document.getElementById('app')
const loadingDomElement = document.getElementById('loading')
const udpateDomElement = document.getElementById('update')
const errorDomElement = document.getElementById('error')
const errorMsgDomElement = document.getElementById('error-msg')
rootDomElement.className = 'bizquiz'

// import * as OfflinePluginRuntime from 'offline-plugin/runtime'

// react intl
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/it'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import {
  RESET,
  MAINTENANCE,
  REGENERATE_TOKEN,
} from './containers/App/constants.js'
import isUpdateAvailable from './update.js'

// check if login with token from url is running
const loginWithTokenFromUrl = location.pathname.match(/login\/.{64}$/) !== null

// read the browser locale
const locale = (
  (window.navigator.languages && window.navigator.languages.length)
    ? window.navigator.languages[0]
    : (window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage)
).toLowerCase().replace('_', '-')

// get the language from locale
const browserLanguage = locale.indexOf('-') >= 0
  ? locale.split('-')[0]
  : locale

let language = supportedLanguageIds.indexOf(browserLanguage) >= 0
  ? browserLanguage
  : 'en'

console.log(`navigator language: ${language}`) // eslint-disable-line

// cut the language from the url
let userHasChangedLanguage = false
const getLocationDirs = () => window.location.pathname.split('/').filter((a) => a)

let locationDirs = getLocationDirs()
if (locationDirs.length && supportedLanguageIds.indexOf(locationDirs[0]) >= 0) {
  const [languageFromUrl, ...dirs] = locationDirs
  const urlWithoutLanguage = `/${dirs.join('/')}`
  language = languageFromUrl
  userHasChangedLanguage = true
  localforage.setItem('language', language)
    .catch((err) => { console.warn(err) }) // eslint-disable-line
  browserHistory.replace(urlWithoutLanguage)
  locationDirs = getLocationDirs()
}

const reload = () => {
  window.location.reload(true)
}

// called in client container saga
let isLoading = true
const hideLoading = (showUpdate) => {
  if (isLoading) {
    isLoading = false
    console.log('hide loading')  // eslint-disable-line
    if (!showUpdate) {
      udpateDomElement.style.display = 'none'
    }
    // on next tick
    setTimeout(() => {
      loadingDomElement.style.opacity = 0

      setTimeout(() => {
        loadingDomElement.style.display = 'none'
      }, 500)
    }, 50)
  }
}

const hasVaildToken = (token) => {
  try {
    const saveToken = `${token}`
    const parts = saveToken.split('.')
    if (parts.length !== 3) {
      return false
    }
    const payload = JSON.parse(atob(parts[1]))
    if (!payload.exp) {
      return false
    }
    if (payload.exp < Date.now() / 1000) {
      return false
    }
    return true
  } catch (e) {
    return false
  }
}

window.hideLoading = hideLoading

let updatePromise = Promise.resolve()

if (VERSION === 'dev') { // eslint-disable-line
  console.log('on development server no version checking') // eslint-disable-line
} else {
  updatePromise = isUpdateAvailable(VERSION) // eslint-disable-line
}

updatePromise.then((hasUpdate) => { // eslint-disable-line
  if (hasUpdate) {
    console.warn('update available') // eslint-disable-line
  }
  // admin is only in en
  if (locationDirs.length && locationDirs[0] === 'admin') {
    return 'en'
  }
  if (userHasChangedLanguage) {
    return language
  }

  return localforage.getItem('language')
    .then((l) => {
      if (supportedLanguageIds.indexOf(l) >= 0) {
        return l
      }
      return language
    })
    .catch(() => language)
}).then((queryOrStorageLang) => {
  let lang = 'en'
  if (queryOrStorageLang && queryOrStorageLang.length === 2) {
    lang = queryOrStorageLang
  }
  // now language is finaly defined
  console.log(`requesting language ${lang}`) // eslint-disable-line
  Api.setLanguage(lang)
  return Promise.all([
    localforage.getItem('store')
      .then((s) => s || {})
      .catch(() => ({})),
  ])
}).then(([value]) => { // eslint-disable-line no-shadow
  // delayed set lang

  // if we are on the root page and a client is in the local storage
  if (
    locationDirs.length === 0
    && value.client
    && value.client.client
    && value.client.client.id
  ) {
    browserHistory.replace(`/${value.client.client.id}`)
    locationDirs = getLocationDirs()
  }

  const clientId = locationDirs.length >= 1 && locationDirs[0] ? locationDirs[0] : 'none'

  return Promise.all([
    value,
    Api.strings(clientId),
  ])
}).then(([value, translationStrings]) => {
  console.log(`using language ${translationStrings.locale}`) // eslint-disable-line
  Api.setLanguage(translationStrings.locale)
  try {
    document.body.classList.add(`bq-lng-${translationStrings.locale}`)
  } catch (e) {
    // pass
  }

  let initialState = {}
  // only load the old state if the client id from the state matches the url
  const clientLocationMatch = (
    value.client
    && value.client.client
    && value.client.client.id
    && locationDirs.length >= 1
    && locationDirs[0] === value.client.client.id
  )

  // only load the state if the login version is the same as the current version
  const versionMatch = (
    value.login
    && value.login.version
    && value.login.version === VERSION // eslint-disable-line
  )

  const hasValidToken = value.login && value.login.token ? hasVaildToken(value.login.token) : false

  if (clientLocationMatch && versionMatch && hasValidToken && !loginWithTokenFromUrl) {
    initialState = value
    window.tracker.setCustomDimension(1, value.client.client.id)
    if (value.login && value.login.email) {
      window.tracker.setUserId(value.login.email)
      if (value.login.events) {
        window.tracker.startRemoteTracking()
      }
    }
  } else if (locationDirs[0] === 'admin') {
    window.tracker.setCustomDimension(1, 'admin')
  }

  initialState = {
    ...initialState,
    language: translationStrings.locale,
  }

  if (initialState && initialState.login && initialState.login.token) {
    Api.setToken(initialState.login.token)
  }

  // enable login page in new tab
  if (initialState && initialState.login) {
    if (initialState.login.success) {
      initialState.login.success = false
    }
    if (initialState.login.loading) {
      initialState.login.loading = false
    }
    if (initialState.login.error) {
      initialState.login.error = null
    }
  }

  const store = configureStore(initialState, browserHistory)

  Api.setOnLogin(() => store.dispatch({ type: RESET }))
  Api.setOnTokenRegenerate((token) => store.dispatch({ type: REGENERATE_TOKEN, token }))
  Api.setOnMaintenanceCallback(() => {
    store.dispatch({ type: MAINTENANCE })
  })
  // Api.setOnPandingRequestCountChange((count) => store.dispatch({ type: REQUEST_COUNT_SET, count }))

  const history = syncHistoryWithStore(browserHistory, store, {
    selectLocationState: selectLocationState(),
  })

  const rootRoute = {
    component: App,
    childRoutes: createRoutes(store),
  }

  // add global refs to plugins
  addPluginOption('store', store)
  addPluginOption('language', translationStrings.locale)
  addPluginOption('history', history)

  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider language={translationStrings.locale} messages={translationStrings.translations}>
        <Router
          history={history}
          routes={rootRoute}
        />
      </LanguageProvider>
    </Provider>,
    rootDomElement,
    () => {
      console.log('init render finished') // eslint-disable-line
      errorDomElement.style.display = 'none'
    }
  )
}).catch((e) => {
  console.error(`init failed: ${e}`) // eslint-disable-line
  console.error(e) // eslint-disable-line
  errorDomElement.style.top = '0px'
  let eta = 30
  const renderError = (t, er) => {
    errorMsgDomElement.innerHTML = `<p style="font-weight: bold;">Something went wrong!</p><p>This page will reload automatically in ${t} seconds.</p><pre style="font-size: 12px;">${er.stack}</pre>`
  }
  renderError(eta, e)
  setInterval(() => {
    eta -= 1
    if (eta <= 0) {
      localforage.removeItem('store').then(reload)
      // reload()
    } else {
      renderError(eta, e)
    }
  }, 1000)
})
