/*
 *
 * LoginPage constants
 *
 */

export const LOGIN_REQUESTED = 'app/LoginPage/LOGIN_REQUESTED'
export const LOGIN_SUCCEEDED = 'app/LoginPage/LOGIN_SUCCEEDED'
export const LOGIN_FAILED = 'app/LoginPage/LOGIN_FAILED'
export const LOGIN_UPDATE_AVATAR = 'app/LoginPage/LOGIN_UPDATE_AVATAR'
export const LOGIN_UPDATE_SLOGAN = 'app/LoginPage/LOGIN_UPDATE_SLOGAN'
export const LOGIN_UPDATE_NICKNAME = 'app/LoginPage/LOGIN_UPDATE_NICKNAME'
export const LOGIN_FAILED_RESET = 'app/LoginPage/LOGIN_FAILED_RESET'
export const LOGIN_UPDATE_SOUNDS = 'app/LoginPage/LOGIN_UPDATE_SOUNDS'
export const LOGIN_ENABLE_LTI = 'app/LoginPage/LOGIN_ENABLE_LTI'
export const LOGIN_UPDATE_SHOW_AVATAR_SELECTION = 'app/LoginPage/LOGIN_UPDATE_SHOW_AVATAR_SELECTION'
export const LOGIN_SET_AVATAR_SELECTED = 'app/LoginPage/LOGIN_SET_AVATAR_SELECTED'
