import React from 'react'
import Immutable from 'immutable'
import FileSaver from 'file-saver'
import lodash from 'lodash'
import styled from 'styled-components'
import {
  createSelector, createSelectorCreator, createStructuredSelector, defaultMemoize,
} from 'reselect'
import tinycolor2 from 'tinycolor2'
import localforage from 'localforage'
import howler from 'howler'
import { getApi } from '../../api.js'
const Api = getApi()

const opts = {}

export const addPluginOption = (key, value) => {
  opts[key] = value
}

export const run = (plugin) => {
  try {
    const pluginFunc = new Function('options', plugin.js) // eslint-disable-line
    pluginFunc({
      React,
      Api,
      Immutable,
      FileSaver,
      styled,
      lodash,
      reselect: {
        createSelector, createSelectorCreator, createStructuredSelector, defaultMemoize,
      },
      tinycolor2,
      localforage,
      howler,
      ...opts,
    })
  } catch (e) {
    console.error(e) // eslint-disable-line
  }
}
