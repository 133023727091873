export const breakpoints = {
  tablet: { // min
    width: 768, // px
    height: 600, // px
  },
  desktop: { // min
    width: 1200, // px
    height: 880, // px
  },
}

export const scaleTarget = {
  width: 120, // rem
  height: 88, // rem
}

// start scaling if smaller then desktop
export const scaleBreakpoint = breakpoints.desktop

export const minScale = 37.5
