/*
 *
 * Foreplay constants
 *
 */

export const TRAINING_FOREPLAY_ACTION = 'app/Foreplay/TRAINING_FOREPLAY_ACTION'
export const CHALLENGE_FOREPLAY_ACTION = 'app/Foreplay/CHALLENGE_FOREPLAY_ACTION'
export const USER_CHALLENGE_FOREPLAY_ACTION = 'app/Foreplay/USER_CHALLENGE_FOREPLAY_ACTION'
export const ACCEPT_USER_CHALLENGE_FOREPLAY_ACTION = 'app/Foreplay/ACCEPT_USER_CHALLENGE_FOREPLAY_ACTION'
export const FOREPLAY_FETCH_REQUESTED = 'app/Foreplay/FOREPLAY_FETCH_REQUESTED'
export const FOREPLAY_FETCH_SUCCEEDED = 'app/Foreplay/FOREPLAY_FETCH_SUCCEEDED'
export const FOREPLAY_FETCH_FAILED = 'app/Foreplay/FOREPLAY_FETCH_FAILED'
export const USER_SEARCH_FOREPLAY_ACTION = 'app/Foreplay/USER_SEARCH_FOREPLAY_ACTION'
export const FOREPLAY_MOUNT = 'app/Foreplay/FOREPLAY_MOUNT'
