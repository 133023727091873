/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from 'redux'
import { fromJS } from 'immutable'
import { routerMiddleware } from 'react-router-redux'
import createSagaMiddleware from 'redux-saga'
import createReducer from './reducers.js'

const sagaMiddleware = createSagaMiddleware()
// eslint-disable-next-line no-underscore-dangle
const devtools = window.__REDUX_DEVTOOLS_EXTENSION__ || (() => (noop) => noop)

import { configureStoreLocalforageMiddleware } from './middleware.js'

// TODO: find better place to register global sagas
import headerSaga from './containers/Header/sagas.js'
import appSaga from './containers/App/sagas.js'

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    configureStoreLocalforageMiddleware(),
  ]

  const enhancers = [
    applyMiddleware(...middlewares),
    devtools(),
  ]

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    compose(...enhancers)
  )

  // Create hook for async sagas
  store.runSaga = sagaMiddleware.run

  // TODO: find better place to register global sagas
  headerSaga.map((saga) => store.runSaga(saga))
  appSaga.map((saga) => store.runSaga(saga))

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    import('./reducers.js').then((reducerModule) => {
      const createReducers = reducerModule.default
      const nextReducers = createReducers(store.asyncReducers)

      store.replaceReducer(nextReducers)
    })
  }

  // Initialize it with no other reducers
  store.asyncReducers = {}
  return store
}
