/*
 *
 * Toasty actions
 *
 */

import {
  CREATE_ACTION,
  REMOVE_ACTION,
  CREATE_FULLSCREEN,
  CLOSE_FULLSCREEN,
} from './constants.js'

let lastId = 0

export function createToastyAction(options) {
  return {
    id: lastId++,
    options,
    type: CREATE_ACTION,
  }
}

export function removeToastyAction(id) {
  return {
    id,
    type: REMOVE_ACTION,
  }
}

export function createFullscreenMessage(notification) {
  return {
    id: lastId++,
    notification,
    type: CREATE_FULLSCREEN,
  }
}

export function closeFullscreenMessage() {
  return {
    type: CLOSE_FULLSCREEN,
  }
}
