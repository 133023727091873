/*
 *
 * Toasty constants
 *
 */

export const CREATE_ACTION = 'app/Toasty/CREATE_ACTION'
export const REMOVE_ACTION = 'app/Toasty/REMOVE_ACTION'
export const CREATE_FULLSCREEN = 'app/Toasty/CREATE_FULLSCREEN'
export const CLOSE_FULLSCREEN = 'app/Toasty/CLOSE_FULLSCREEN'
