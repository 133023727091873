/*
 *
 * Header constants
 *
 */

export const SHOW_ACTION = 'app/Header/SHOW_ACTION'
export const HIDE_ACTION = 'app/Header/HIDE_ACTION'
export const NOTIFICATIONS_FETCH_REQUESTED = 'app/Header/NOTIFICATIONS_FETCH_REQUESTED'
export const LOGOUT_REQUESTED = 'app/Header/LOGOUT_REQUESTED'
export const HIGHLIGHT = 'app/Header/HIGHLIGHT'
export const CLEAR_HIGHLIGHT = 'app/Header/CLEAR_HIGHLIGHT'
