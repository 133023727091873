export const dummyDriver = {
  _driver: 'bq-dummy-driver',
  _initStorage: () => new Promise((resolve) => resolve()),
  _support: true,
  clear: (callback) => new Promise((resolve) => {
    if (callback) {
      callback()
    }
    resolve()
  }),
  getItem: (key, callback) => new Promise((resolve, reject) => {
    const err = new Error('Not found')
    if (callback) {
      callback(err)
    } else {
      reject(err)
    }
  }),
  key: (n, callback) => new Promise((resolve, reject) => {
    const err = new Error('Not found')
    if (callback) {
      callback(err)
    } else {
      reject(err)
    }
  }),
  keys: (callback) => new Promise((resolve) => {
    if (callback) {
      callback([])
    }
    resolve([])
  }),
  length: (callback) => new Promise((resolve) => {
    if (callback) {
      callback(0)
    }
    resolve(0)
  }),
  removeItem: (key, callback) => new Promise((resolve) => {
    if (callback) {
      callback()
    }
    resolve()
  }),
  setItem: (key, value, callback) => new Promise((resolve) => {
    if (callback) {
      callback()
    }
    resolve()
  }),
  iterate: (iterator, callback) => new Promise((resolve) => {
    if (callback) {
      callback()
    }
    resolve()
  }),
}
