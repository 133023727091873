/*
 *
 * ClientContainer reducer
 *
 */

import { fromJS } from 'immutable'
import createReducer from '../../createReducer.js'
import {
  CLIENT_FETCH_SUCCEEDED,
  CLIENT_FETCH_FAILED,
} from './constants.js'

const initialState = fromJS({
  loaded: false,
  error: null,
  client: null,
})

export const reducer = createReducer(initialState, {

  // do not RESET the client! It is required on the login page

  [CLIENT_FETCH_SUCCEEDED](state, action) {
    return state.merge({
      client: action.client,
      loaded: true,
      error: null,
    })
  },

  [CLIENT_FETCH_FAILED](state, action) {
    return state.merge({
      loaded: false,
      error: action.message,
      info: null,
    })
  },
})

export default reducer
