/*
 *
 * Header reducer
 *
 */

import { fromJS } from 'immutable'
import { RESET } from '../App/constants.js'
import createReducer from '../../createReducer.js'
import {
  SHOW_ACTION,
  HIDE_ACTION,
  HIGHLIGHT,
  CLEAR_HIGHLIGHT,
} from './constants.js'

const initialState = fromJS({
  visible: true,
  highlight: null,
})

export const reducer = createReducer(initialState, {

  [RESET]() {
    return initialState
  },

  [SHOW_ACTION](state) {
    return state.merge({
      visible: true,
    })
  },

  [HIDE_ACTION](state) {
    return state.merge({
      visible: false,
    })
  },

  [HIGHLIGHT](state, action) {
    return state.merge({
      highlight: action.highlight,
    })
  },

  [CLEAR_HIGHLIGHT](state) {
    return state.merge({
      highlight: null,
    })
  },

})

export default reducer
