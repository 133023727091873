/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
*
* InventoryItem
*
*/

import React from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import clientConnect from '../../containers/ClientContainer/connect.js'
import { createThemeStyles } from '../../theme.js'
import styled from 'styled-components'
import { openInventory } from '../../containers/App/actions.js'

const InventoryItemBody = styled.div`
  flex: 0 0 auto;
  background-position: center;
  background-size: contain;
  cursor: pointer;
`

class InventoryItem extends React.Component { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    theme: PropTypes.object, // eslint-disable-line
    style: PropTypes.object,
    item: PropTypes.object,
    className: PropTypes.string,
    size: PropTypes.number,
    openInventory: PropTypes.func,
  }

  static defaultProps = {
    size: 48,
  }

  getThemeStyles = createThemeStyles('InventoryItem', (opts) => ({ // eslint-disable-line
    // backgroundColor: opts.backgroundDark,
  }))

  render() {
    const { style, className, item, size, openInventory } = this.props // eslint-disable-line
    const themeStyles = this.getThemeStyles()
    return (
      <InventoryItemBody
        className={className}
        style={{
          ...style,
          ...themeStyles,
          backgroundImage: `url(${item.image})`,
          width: size,
          height: size,
        }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          openInventory(item)
        }}
      />
    )
  }
}

export default connect(() => ({}), (dispatch) => ({
  openInventory: (item) => dispatch(openInventory(item)),
}))(clientConnect(InventoryItem))
